<template>
  <div>
    <button class="btn btn-primary" id="treeBtnStart" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Évek</button>

    <div class="show offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
      id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <button type="button" data-toggle="tooltip" data-placement="left" title="Logout" class="btn btn-secondary"
          id="logoutButton" @click="logOut()">
          <i class="fa-solid fa-right-from-bracket"></i>
        </button>
        <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Éves Adatok és Vetésforgók Áttekintése</h5>
        <button type="button" class="btn btn-primary" id="btnX" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="picture1"><!--<img src="images/logo.jpg" class="rounded mx-auto d-block" alt="...">--></div>
        <div class="selectYear">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false" @click="isShow = hide">
              2022
            </button>
            <ul class="dropdown-menu">
              <button type="button" class="btn btn-primary" id="vetes22" @click="isShow = !isShow; forgo22()">
                Vetésforgó
              </button>
              <button type="button" class="btn btn-primary" id="qgis1" @click="qgis2022()">
                QGIS 2022
              </button>
            </ul>
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false" @click="isShow = hide">
              2023
            </button>
            <ul class="dropdown-menu">
              <button type="button" class="btn btn-primary" id="vetes23" href="#" @click="isShow = !isShow; forgo23()">
                Vetésforgó
              </button>
              <button type="button" class="btn btn-primary" id="qgis2" @click="qgis2023()">
                QGIS 2023
              </button>
            </ul>
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false" @click="isShow = hide">
              2024
            </button>
            <ul class="dropdown-menu">
              <button type="button" class="btn btn-primary" disabled id="vetes23" @click="isShow = !isShow">
                Vetésforgó
              </button>
              <button type="button" class="btn btn-primary" disabled id="qgis2" href="#">
                QGIS 2024
              </button>
            </ul>
          </div>
        </div>

        <div v-show="isShow" id="notShown">
          <div class="closeX2">
            <button class="btn" type="button" id="closeX2" @click="isShow = hide">X</button>
          </div>
          <div class="overflow-y-scroll">
            <table class="table-transparent" id="liveData-table">
              <tr>
                <th>Fenyőmajor</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample1"
                    aria-controls="offcanvasScrolling" @click="flyto1()">Info</button></th>
              </tr>
              <tr>
                <th>80/1 Lajtapart</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample2"
                    aria-controls="offcanvasScrolling" @click="flyto2()">Info</button></th>
              </tr>
              <tr>
                <th>80/1</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample2"
                    aria-controls="offcanvasScrolling" @click="flyto2()">Info</button></th>
              </tr>
              <tr>
                <th>OTK</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample3"
                    aria-controls="offcanvasScrolling" @click="flyto3()">Info</button></th>
              </tr>
              <tr>
                <th>Betongödör</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample4"
                    aria-controls="offcanvasScrolling" @click="flyto4()">Info</button></th>
              </tr>
              <tr>
                <th>Lajtapart</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample5"
                    aria-controls="offcanvasScrolling" @click="flyto5()">Info</button></th>
              </tr>
              <tr>
                <th>K6 műút melletti</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample6"
                    aria-controls="offcanvasScrolling" @click="flyto6()">Info</button></th>
              </tr>
              <tr>
                <th>K6 Lajtapart</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample7"
                    aria-controls="offcanvasScrolling" @click="flyto7()">Info</button></th>
              </tr>
              <tr>
                <th>Nyúlgát</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample8"
                    aria-controls="offcanvasScrolling" @click="flyto8()">Info</button></th>
              </tr>
              <tr>
                <th>Nyúlgát BIO</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample9"
                    aria-controls="offcanvasScrolling" @click="flyto9()">Info</button></th>
              </tr>
              <tr>
                <th>Temetői</th>
                <th><button class="btnInfo" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample10"
                    aria-controls="offcanvasExample" @click="flyto10()">Info</button></th>
              </tr>
              <tr>
                <th>K/3 Genezises</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling"
                    data-bs-target="#offcanvasExample11" @click="flyto11()">Info</button></th>
              </tr>
              <tr>
                <th>Genezis tartamkísérlet</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample12"
                    aria-controls="offcanvasScrolling" @click="flyto12()">Info</button></th>
              </tr>
              <tr>
                <th>K/3 spicc</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample13"
                    aria-controls="offcanvasScrolling" @click="flyto13()">Info</button></th>
              </tr>
              <tr>
                <th>K/3 versenyforgó</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample14"
                    aria-controls="offcanvasScrolling" @click="flyto14()">Info</button></th>
              </tr>
              <tr>
                <th>Kökényes</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample15"
                    aria-controls="offcanvasScrolling" @click="flyto15()">Info</button></th>
              </tr>
              <tr>
                <th>K/2 szérűskerti</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample16"
                    aria-controls="offcanvasScrolling" @click="flyto16()">Info</button></th>
              </tr>
              <tr>
                <th>K/2 romboló</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample17"
                    aria-controls="offcanvasScrolling" @click="flyto17()">Info</button></th>
              </tr>
              <tr>
                <th>K/2 tyúkólak</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample18"
                    aria-controls="offcanvasScrolling" @click="flyto18()">Info</button></th>
              </tr>
              <tr>
                <th>K/2 spicc</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample19"
                    aria-controls="offcanvasScrolling" @click="flyto19()">Info</button></th>
              </tr>
              <tr>
                <th>Lőtéri</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample20"
                    aria-controls="offcanvasScrolling" @click="flyto20()">Info</button></th>
              </tr>
              <tr>
                <th>Borjúnevelő</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample21"
                    aria-controls="offcanvasScrolling" @click="flyto21()">Info</button></th>
              </tr>
              <tr>
                <th>Bugabányai</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample22"
                    aria-controls="offcanvasScrolling" @click="flyto22()">Info</button></th>
              </tr>
              <tr>
                <th>Kápolnai</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample23"
                    aria-controls="offcanvasScrolling" @click="flyto23()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia jobb oldal</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia bal oldal (tanyás)</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia lucernás</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia dögkút</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia legelő</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia legelő melletti</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
              <tr>
                <th>Biotechnológia Lajtamag melletti</th>
                <th><button type="button" class="btnInfo" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample24"
                    aria-controls="offcanvasScrolling" @click="flyto24()">Info</button></th>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div v-drag v-show="isShow" id="notShownVetes">
      <div class="overflow-y-scroll">
        <button class="btn" id="closeX" @click="isShow = hide">X</button>
        <table class="table-transparent" id="liveVetes-table">
          <tr v-for="a in kulturak" :key="a.name">
            <th>{{ a.name }}</th>
            <th :style="{ 'background-color': a.color, 'opacity': none }"> </th>
          </tr>
        </table>
      </div>
    </div>

    <div class="container">
      <Mapbox :accessToken="accessToken" :mapStyle="mapStyle" :zoom="zoom" :center="center" id="map"
        @load="onMapLoaded, alap()" />
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample1" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample1Label">
          Fenyőmajor </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/imgfenyomaj2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/imgfenyomaj1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample2" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample2Label">
          80/1 Lajtapart </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel2" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel2" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel2" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/lajta81.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/lajta82.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample3" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample3Label">
          OTK </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel3" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel3" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel3" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/otk1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/otk2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel3" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel3" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample4" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample4Label">
          Betongödör </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel4" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel4" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel4" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/beton2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/beton1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel4" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel4" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample5" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample5Label">
          Lajtapart
        </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="qgis6Lajta()" id="btn3d">
            QGIS
          </button>
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel5" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel5" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel5" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carousel5" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carousel5" data-bs-slide-to="3" aria-label="Slide 4"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/lajta1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img112.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img113.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img114.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel5" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel5" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <table class="table-transparent" id="info-table">
          <thead>
            <tr>
              <th>Kulcsszavak:</th>
              <td>tavaszi árpa, búza, kisparcella</td>
            </tr>
            <tr>
              <th>Projekt várható végeredménye:</th>
              <td>
                Növényállomány adatok, terméseredmény adatok, tudományos
                cikkek megjelentetése, diplomamunka.
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample6" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample6Label">
          K6 Műút Melletti </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel6" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel6" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel6" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/k6m2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/k6m1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel6" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel6" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample7" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">

      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample7Label">
          K6 Lajtapart
        </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel7" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="4" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="5" aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#carousel7" data-bs-slide-to="6" aria-label="Slide 7"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/k6lajta1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img102.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img103.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img104.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img105.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img106.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/img107.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel7" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel7" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <table class="table-transparent" id="info-table">
          <thead>
            <tr>
              <th>Kulcsszavak:</th>
              <td>tavaszi árpa, búza, kisparcella</td>
            </tr>
            <tr>
              <th>Projekt várható végeredménye:</th>
              <td>
                Növényállomány adatok, terméseredmény adatok, tudományos
                cikkek megjelentetése, diplomamunka, szerződéses vállalás.
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample8" data-bs-backdrop="false"
      aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample8Label">
          Nyúlgát </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>
        <div id="carousel8" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel8" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carousel8" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/nyg2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyg1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel8" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel8" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>


    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample9" data-bs-backdrop="false"
      aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample9Label">
          Nyúlgát BIO
        </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>

        <div id="carousel9" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel9" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carousel9" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carousel9" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carousel9" data-bs-slide-to="4" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carousel9" data-bs-slide-to="5" aria-label="Slide 6"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/nyulgatbio6.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyulgatbio1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyulgatbio2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyulgatbio3.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyulgatbio4.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/nyulgatbio5.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel9" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel9" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="video-container">
          <div class="videoLinks">
            <button class="btn btn-primary" id="videoLink" target="VidFrame"
              href="https://www.youtube.com/embed/Yolt3ZEx_P0"><a id="videoLink" target="VidFrame"
                href="https://www.youtube.com/embed/Yolt3ZEx_P0"> Videó 1</a></button>
            <button class="btn btn-primary" id="videoLink" target="VidFrame"
              href="https://www.youtube.com/embed/3p8IA6-DrRQ"><a id="videoLink" target="VidFrame"
                href="https://www.youtube.com/embed/3p8IA6-DrRQ"> Videó 2</a></button>
          </div>
          <iframe name="VidFrame" width="auto" height="auto" id="player"
            src="https://www.youtube.com/embed/3p8IA6-DrRQ?rel=0&amp;autoplay=1"></iframe>
        </div>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample10" data-bs-backdrop="false"
      aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExample10Label">
          Temetői
        </h5>
        <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="btn-row" style="text-align: center">
          <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
            Főépület 3D
          </button>
          <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
            Műhely 3D
          </button>
        </div>

        <div id="carousel10" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel10" data-bs-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel10" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="images/temet1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
            <div class="carousel-item">
              <img src="images/temet2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel10" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel10" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <table class="table-transparent" id="info-table">
          <thead>
            <tr>
              <th>Kulcsszavak:</th>
              <td>kukorica, éghajlatváltozás, fenológia</td>
            </tr>
            <tr>
              <th>Projekt várható végeredménye:</th>
              <td>
                A hosszú, agroklimatológiai jellegű adatbázist bővítő makro-
                és mikroklímát jellemző meteorológiai adatok, kukorica
                fenológiai (fejlődési, növekedési, produktivitási) adatok,
                ezekből lektorált tudományos cikkek megjelentetése.
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample11" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample11Label">
        K/3 Genezis
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="qgisGenK3()" id="btn3d">
          QGIS
        </button>
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel11" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel11" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel11" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carousel11" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carousel11" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/gen2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/gen1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/img71.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/img72.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carousel11" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carousel11" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <table class="table-transparent" id="info-table">
        <thead>
          <tr>
            <th>Kulcsszavak:</th>
            <td>tavaszi vetésű növények, növénykondícionálás</td>
          </tr>
          <tr>
            <th>Projekt várható végeredménye:</th>
            <td>
              A megbízó által kért növényállomány adatok,
              növényegészségügyi adatok, termés paraméterekszolgáltatási
              díj.
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample12" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample12Label">
        Genezises Tartamkisérlet
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel12" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel12" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel12" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carousel12" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carousel12" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carousel12" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/tartamk1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/tartamk2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/img162.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/img163.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-item">
            <img src="images/img164.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carousel12" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carousel12" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>

      </div>
      <table class="table-transparent" id="info-table">
        <thead>
          <tr>
            <th>Kulcsszavak:</th>
            <td>
              Tápanyagutánpótlás, Nitrogén fejtrágyázás, Lombtrágyázás
            </td>
          </tr>
          <tr>
            <th>Projekt várható végeredménye:</th>
            <td>
              Növényállomány adatok, terméseredmény adatok,
              tápanyagutánpótlási technológia értékelése, szerződéses
              vállalás.
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample13" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample13Label">
        K/3 Spicc
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel13" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel13" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel13" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/k3s2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/k3s1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 50vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel13" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel13" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample14" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample14Label">
        K/3 Versenyforgó
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel14" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel14" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel14" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/k3v2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/k3v1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel14" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel14" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="video-container">
        <div class="videoLinks">
          <button class="btn btn-primary" id="videoLink" target="VidFrame"
            href="https://www.youtube.com/embed/vMjg75Qls7Q"><a id="videoLink" target="VidFrame2"
              href="https://www.youtube.com/embed/vMjg75Qls7Q"> Videó 1</a></button>
          <button class="btn btn-primary" id="videoLink" target="VidFrame"
            href="https://www.youtube.com/embed/CC7eSKsPJBM"><a id="videoLink" target="VidFrame2"
              href="https://www.youtube.com/embed/CC7eSKsPJBM"> Videó 2</a></button>
          <button class="btn btn-primary" id="videoLink" target="VidFrame"
            href="https://www.youtube.com/embed/DxsicBLkaok"><a id="videoLink" target="VidFrame2"
              href="https://www.youtube.com/embed/DxsicBLkaok"> Videó 3</a></button>
        </div>
        <iframe name="VidFrame2" id="player" width="auto" height="auto"
          src="https://www.youtube.com/embed/DxsicBLkaok?rel=0&amp;autoplay=1"></iframe>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample15" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample15Label">
        Kökényes
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel15" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel15" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel15" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/kokeny2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/kokeny1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel15" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel15" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample16" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample16Label">
        K/2 Szérüskerti
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel16" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel16" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel16" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/kokeny2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/kokeny1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel16" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel16" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample17" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample17Label">
        K/2 Romboló
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel17" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel17" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse117" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/rom2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/rom1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 40vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel17" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel17" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <table class="table-transparent" id="info-table">
        <thead>
          <tr>
            <th>Kulcsszavak:</th>
            <td>Drón, Smart Farm IOT rendszer</td>
          </tr>
          <tr>
            <th>Projekt várható végeredménye:</th>
            <td>
              Mezőgazdaság, drón, szenzor pilot program egy olyan, az 5G
              kommunikációra épülő DRÓN irányítási minta megoldás
              kifejlesztése - és bemutatása - amely segítségével a jövő
              mezőgazdasági termelésében a drónok monitorozó és beavatkozó
              funkciói automatizálhatóvá válnak.
              Az elérni kívánt prototipizálás célja a technológia ilyen
              irányú használatának bemutatása.
            </td>
          </tr>
        </thead>
      </table>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample18" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample18Label">
        K/2 Tyúkólak
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel18" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel18" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse118" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/tyuk1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/tyuk2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel18" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel18" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample19" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample19Label">
        K/2 Spicc
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel19" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel19" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse119" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/k2spicc1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/k2spicc2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel19" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel19" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample20" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample20Label">
        Lőtéri
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel20" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel20" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse120" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/loter1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/loter2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel20" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel20" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample21" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample21Label">
        Borjúnevelő
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel21" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel21" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse121" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/borju1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/borju2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel21" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel21" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample22" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample22Label">
        Bugabányai
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel22" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel22" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse122" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/buga1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/buga2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel22" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel22" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample23" data-bs-backdrop="false"
    aria-labelledby="offcanvasScrollingLabel">

    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample23Label">
        Kápolnai
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel23" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel23" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouse123" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/kapolna1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/kapolna2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel23" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel23" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample24" data-bs-backdrop="false"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExample24Label">
        Biotechnologia
      </h5>
      <button class="btn btn-primary" id="treeBtn" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="hello()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="btn-row" style="text-align: center">
        <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
          Főépület 3D
        </button>
        <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
          Műhely 3D
        </button>
      </div>
      <div id="carousel24" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carousel24" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel24" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="images/biolab1.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item">
              <img src="images/biolab2.jpg" class="d-block w-100" alt="..." style="width: auto; height: 45vh;" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carousel24" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carousel24" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="https://grafana.smartfarm.ddc.sze.hu/d/e363dcd1-4bf8-4984-a17e-1a97716cf8d7/libelium-sensor-1?orgId=1"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart4" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart4 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig4" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="http://localhost:3000/d/ddhjr3jbnjncwa/libelium-0004a30b00276562?orgId=1&from=1712088442289&to=1712098474489"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart5" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart5 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig5" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="http://localhost:3000/d/edhjvbdm7ad4wf/metos-0004a30b00f778a4?orgId=1"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart6" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart6 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig6" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="http://localhost:3000/d/edhjvqka2b9q8e/metos-0004a30b00f77b82?orgId=1"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart7" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart7 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig7" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="http://localhost:3000/d/cdhjwg9f5zabka/metos-0004a30b00f78734?orgId=1"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart8" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart8 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig8" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="http://localhost:3000/public-dashboards/c04d03e3aff841b0ba387bde2d6c507e"
          width="660" height="440" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div v-drag v-show="chart2" class="xbutt">
    <button class="btn" type="button" id="closeX3" @click="chart2 = hide">X</button>
    <div id="chart">
      <h5 id="chartH5"> Ide kattintva lehet mozgatni az ablakot</h5>
      <button class="btn" type="button" id="makeItBig2" @click="makeItBig()">◱</button>
      <div>
        <iframe id="sensor"
          src="https://ddchost2.tilb.sze.hu/livestream/"
          width="560" height="460" frameborder="10"></iframe>
      </div>
    </div>
  </div>

  <div class="modelView" id="modelContainer1" ref="canvasContainer">
    <button class="btn btn-secondary" id="back-button" @click="goBack()">
      <i class="fa-solid fa-arrow-left"></i>
    </button>
  </div>

  <div id="dummyBlack">
    <semipolar-spinner :animation-duration="2000" :size="65" color="#0177a3" />
  </div>
</template>
<script>
import * as THREE from "three";
import Mapbox from "vue-mapbox";
//import { SemipolarSpinner } from "epic-spinners";
import MapboxGl from "mapbox-gl";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import * as mqtt from 'mqtt';
//import dataProvider from "../utils/dataProvider.js";
//const dp = dataProvider();
//import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
export default {
  name: "MapComponent",
  components: {
    Mapbox,
    //SemipolarSpinner,
  },
  data() {
    return {
      map: null,
      originalMap: null,
      isShow: false,
      chart: false,
      chart2: false,
      chart4: false,
      chart5: false,
      chart6: false,
      chart7: false,
      chart8: false,
      // active: false,
      is101: false,
      is102: false,
      is103: false,
      is104: false,
      is201: false,
      is202: false,
      is3: false,
      is4: false,
      is5: false,
      is6111: false,
      is6112: false,
      is612: false,
      is62: false,
      is71: false,
      is72: false,
      is81: false,
      is82: false,
      is9: false,
      is10: false,
      is11: false,
      is12: false,
      is131: false,
      is132: false,
      is133: false,
      is134: false,
      is14: false,
      is15: false,
      is16: false,
      is17: false,
      is18: false,
      is19: false,
      is20: false,
      is21: false,
      is22: false,
      is23: false,
      is24: false,
      is25: false,
      is26: false,
      is27: false,
      is28: false,
      is29: false,
      is30: false,
      is31: false,
      big: false,
      vetes22: [
        { id: '101-1_1', color: '#21d618' }, //green Őszi búza
        { id: '102-1_2', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '103-1_3', color: '#cdd1d1' }, //grey Takarmányrépa
        { id: '104-1_4', color: '#21d618' }, //green Őszi búza
        { id: '201-80_1_lajtapart_bal_oldal_facelia_2023', color: '#21d618' }, //green Őszi búza
        { id: '202-80_1_lajtapart_jobb_oldal', color: '#21d618' }, //green Őszi búza
        { id: '3-80_1', color: '#21d618' }, //green Őszi búza
        { id: '4-OTK', color: '#889153' }, //khaki Kukurica
        { id: '5-Betongodor', color: '#889153' }, //khaki Kukurica
        { id: '6111_Lajtapart_bal_oldal_2023', color: '#b339b8' }, //magenta Facélia
        { id: '6112_Lajtapart_bal_oldal_2023', color: '#b339b8' }, //magenta Facélia
        { id: '612-Lajtapart_bal_oldal', color: '#b339b8' }, //magenta Facélia
        { id: '62-Lajtapart_jobb_oldal', color: '#889153' }, //khaki Kukurica
        { id: '71-K6_muut_melletti_bal_oldal', color: '#21d618' }, //green Őszi búza
        { id: '72-K6_muut_melletti_jobb_oldal', color: '#21d618' }, //green Őszi búza
        { id: '81-K6_Lajtapart_Komlo', color: '#edde11' },//yellow Komló
        { id: '82-K6_Lajtapart', color: '#b01214' }, //red Tavaszi árpa
        { id: '9-Nyulgat', color: '#21d618' }, //green Őszi búza
        { id: '10-Nyulgat_BIO', color: '#b339b8' }, //magenta Facélia
        { id: '11-Temetoi_polygon', color: '#21d618' }, //green Őszi búza
        { id: '12-K_3_genezises', color: '#889153' }, //khaki Kukurica
        { id: '131-Genezis_tartamkiserlet_1', color: '#21d618' },//green Őszi búza
        { id: '132-Genezis_tartamkiserlet_2', color: '#889153' }, //khaki Kukurica
        { id: '133-Genezis_tartamkiserlet_3', color: '#b01214' }, //red Tavaszi árpa
        { id: '134-Genezis_tartamkiserlet_4', color: '#fff' }, //white
        { id: '14-K_3_spicc', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '15-K_3_versenyforgo', color: '#889153' }, //khaki Kukurica
        { id: '16-Kokenyes', color: '#889153' }, //khaki Kukurica
        { id: '17-K_2_szeruskerti', color: '#889153' }, //khaki Kukurica
        { id: '18-K_2_rombolo', color: '#889153' }, //khaki Kukurica
        { id: '19-K_2_tyukolak', color: '#b01214' }, //red Tavaszi árpa
        { id: '20-k_2_spicc', color: '#889153' }, //khaki Kukurica
        { id: '21-Loter', color: '#889153' }, //khaki Kukurica
        { id: '22-Borjunevelo', color: '#21d618' },//green Őszi búza
        { id: '23-Bugabanyai', color: '#889153' }, //khaki Kukurica
        { id: '24-Kapolnai', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '25-Biotechnologia_jobb_oldal', color: '#b339b8' }, //magenta Facélia,
        { id: '26-Biotechnologia_bal_oldal_(Tanyas)', color: '#b339b8' }, //magenta Facélia
        { id: '27-Biotechnologia_lucernas', color: '#48165c' }, //purple Lucernás
        { id: '28-Biotechnologia_dogkut', color: '#21d618' },//green Őszi búza
        { id: '29-Biotechnologia_legelo', color: '#6f7575' }, //dark grey Állandó gyep
        { id: '30-Biotechnologia_legelo_melletti', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '31-Biotechnologia_Lajtamag_melletti', color: '#10c2bc' }, //aqua Őszi árpa
      ],
      vetes23: [
        { id: '101-1_1', color: '#21d618' }, //green Őszi búza
        { id: '102-1_2', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '103-1_3', color: '#cdd1d1' }, //grey Takarmányrépa
        { id: '104-1_4', color: '#21d618' }, //green Őszi búza
        { id: '201-80_1_lajtapart_bal_oldal_facelia_2023', color: '#b339b8' }, //magenta Facélia
        { id: '202-80_1_lajtapart_jobb_oldal', color: '#889153' }, //khaki Kukurica
        { id: '3-80_1', color: '#889153' }, //khaki Kukurica
        { id: '4-OTK', color: '#21d618' }, //green Őszi búza' },
        { id: '5-Betongodor', color: '#889153' }, //khaki Kukurica
        { id: '6111_Lajtapart_bal_oldal_2023', color: '#21d618' }, //green Őszi búza
        { id: '6112_Lajtapart_bal_oldal_2023', color: '#b01214' }, //red Tavaszi árpa
        { id: '612-Lajtapart_bal_oldal', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '62-Lajtapart_jobb_oldal', color: '#889153' }, //khaki Kukurica
        { id: '71-K6_muut_melletti_bal_oldal', color: '#cdd1d1' }, //grey Takarmányrépa
        { id: '72-K6_muut_melletti_jobb_oldal', color: '#15631d' }, //dark green Olaszperje
        { id: '81-K6_Lajtapart_Komlo', color: '#edde11' },//yellow Komló
        { id: '82-K6_Lajtapart', color: '#21d618' }, //green Őszi búza
        { id: '9-Nyulgat', color: '#b339b8' }, //magenta Facélia
        { id: '10-Nyulgat_BIO', color: '#b01214' }, //red Tavaszi árpa
        { id: '11-Temetoi_polygon', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '12-K_3_genezises', color: '#889153' }, //khaki Kukurica
        { id: '131-Genezis_tartamkiserlet_1', color: '#889153' }, //khaki Kukurica
        { id: '132-Genezis_tartamkiserlet_2', color: '#b01214' }, //red Tavaszi árpa
        { id: '133-Genezis_tartamkiserlet_3', color: '#ebf216' }, //sun yellow Őszi káposztarepce
        { id: '134-Genezis_tartamkiserlet_4', color: '#21d618' }, //green Őszi búza
        { id: '14-K_3_spicc', color: '#b339b8' }, //magenta Facélia
        { id: '15-K_3_versenyforgo', color: '#889153' }, //khaki Kukurica
        { id: '16-Kokenyes', color: '#21d618' }, //green Őszi búza
        { id: '17-K_2_szeruskerti', color: '#889153' }, //khaki Kukurica
        { id: '18-K_2_rombolo', color: '#889153' }, //khaki Kukurica
        { id: '19-K_2_tyukolak', color: '#b339b8' }, //magenta Facélia
        { id: '20-k_2_spicc', color: '#889153' }, //khaki Kukurica
        { id: '21-Loter', color: '#21d618' }, //green Őszi búza
        { id: '22-Borjunevelo', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '23-Bugabanyai', color: '#21d618' }, //green Őszi búza
        { id: '24-Kapolnai', color: '#889153' }, //khaki Kukurica
        { id: '25-Biotechnologia_jobb_oldal', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '26-Biotechnologia_bal_oldal_(Tanyas)', color: '#10c2bc' }, //aqua Őszi árpa
        { id: '27-Biotechnologia_lucernas', color: '#48165c' }, //purple Lucernás
        { id: '28-Biotechnologia_dogkut', color: '#b339b8' }, //magenta Facélia
        { id: '29-Biotechnologia_legelo', color: '#6f7575' }, //dark grey Állandó gyep
        { id: '30-Biotechnologia_legelo_melletti', color: '#b339b8' }, //magenta Facélia
        { id: '31-Biotechnologia_Lajtamag_melletti', color: '#0748b0' } //blue Cirok
      ],
      kulturak: [
        { name: 'Őszi búza', color: '#21d618' }, //green Őszi búza
        { name: 'Őszi árpa', color: '#10c2bc' }, //aqua Őszi árpa
        { name: 'Takarmányrépa', color: '#cdd1d1' }, //grey Takarmányrépa
        { name: 'Kukorica', color: '#889153' }, //khaki Kukurica
        { name: 'Facélia', color: '#b339b8' }, //magenta Facélia
        { name: 'Komló', color: '#edde11' },//yellow Komló
        { name: 'Tavaszi árpa', color: '#b01214' }, //red Tavaszi árpa
        { name: 'Cirok', color: '#0748b0' }, //blue Cirok
        { name: 'Kiserlet', color: '#fff' }, //white kisérlet
        { name: 'Lucernás', color: '#48165c' }, //purple Lucernás
        { name: 'Állandó gyep', color: '#6f7575' }, //dark grey Állandó gyep
        { name: 'Olaszperje', color: '#15631d' }, //dark green Olaszperje
        { name: 'Őszi káposztarepce', color: '#ebf216' } //sun yellow Őszi káposztarepce
      ],
      linestring: {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'properties': {},
              'coordinates': [

              ]
            }
          }
        ]
      },
      connection: {
        protocol: "wss",
        host: "iot2.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8084,
        endpoint: "",
        rejectUnauthorized: false,
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "smartfarm_frontend" + Math.random().toString(16).substring(2, 8),
        username: "smartfarm_frontend",
        password: "smartfarm_frontend",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
    };
  },
  async created() {
    this.map = null;
  },
  async mounted() {
    this.map = new MapboxGl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/dark-v11",
      projection: "globe",
      accessToken:
        "pk.eyJ1IjoiY3NhYmluZW1ldGgiLCJhIjoiY2xlOGt3YjVmMGdkbjN2bmJ6cHk4ZWN6eCJ9.VwQ_9iKWgBNkt6lEs0vsvg",
      zoom: 12,
      center: [17.23, 47.88],
      antialias: true,
      pitch: 45,
      bearing: -18,
    });
    this.map.on("style.load", () => {
      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#3AB394",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    });
    this.map.on("load", () => {
      this.map.addSource("my-data", {
        type: "geojson",
        data: "osszesitett01.geojson",
      });
      this.map.addLayer({
        id: "my-data-layer",
        type: "fill-extrusion",
        source: "my-data",
        paint: {
          "fill-extrusion-color": "#4A8376",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 5,
        },
      });
      this.map.flyTo({ center: [17.23, 47.88], zoom: 12 });
      this.allPolygons();
      this.map.addSource('LineString', {
        'type': 'geojson',
        'data': this.linestring
      });
      this.map.addLayer({
        'id': 'LineString',
        'type': 'line',
        'source': 'LineString',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#BF93E4', //if want to display on click event change to 'trasnparent'
          'line-width': 5
        }
      });
    });
    var nav = new MapboxGl.NavigationControl();
    this.map.addControl(nav, 'bottom-right');
    const el = document.createElement('div');
    el.className = 'marker';
    el.id = 'GPSTracker01';
    el.style.backgroundImage = 'url(images/tractor-icon.png)';
    el.style.width = '30px';
    el.style.height = '45px';
    el.style.backgroundSize = 'cover';
    el.properties = {
      title: 'Erőgép típusa: New Holland',
      description: 'Kamera stream',
      /* p: 'Élő videó kép', */
    };
    new MapboxGl.Marker(el)
      .setLngLat([17.23, 47.88])
      .setPopup(new MapboxGl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<br>
          <h6>${el.properties.title}</h6>
          <h6>${el.properties.description}</h6>`
/*           <button class="btn" type="button" id="liveStreamBtn" >${el.properties.p}</button> */
        ))
      .addTo(this.map)

      el.addEventListener('click', () => {
      this.chart2 = !this.chart2;
    }
    );
/* 
         document.getElementById("liveStreamBtn").addEventListener('click', () => {
      this.chart2 = !this.chart2;}) */

    const el3 = document.createElement('div');
    el3.className = 'marker3';
    el3.style.backgroundImage = 'url(images/weather-icon.png)';
    el3.style.width = '30px';
    el3.style.height = '45px';
    el3.style.backgroundSize = 'cover';
    el3.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Smart Agriculture Xtreme'
    };
    new MapboxGl.Marker(el3)
      .setLngLat([17.2720849, 47.8807436])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el3.properties.title}</h6>
          <h5>${el3.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el3.addEventListener('click', () => {
      this.chart = !this.chart;
    }
    );

    const el4 = document.createElement('div');
    el4.className = 'marker4';
    el4.style.backgroundImage = 'url(images/weather-icon.png)';
    el4.style.width = '30px';
    el4.style.height = '45px';
    el4.style.backgroundSize = 'cover';
    el4.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Smart Agriculture Xtreme II.'
    };
    new MapboxGl.Marker(el4)
      .setLngLat([17.2675933, 47.89209035])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el4.properties.title}</h6>
          <h5>${el4.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el4.addEventListener('click', () => {
      this.chart4 = !this.chart4;
    }
    );
    

    const el5 = document.createElement('div');
    el5.className = 'marker5';
    el5.style.backgroundImage = 'url(images/weather-icon.png)';
    el5.style.width = '30px';
    el5.style.height = '45px';
    el5.style.backgroundSize = 'cover';
    el5.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Metos I.'
    };
    new MapboxGl.Marker(el5)
      .setLngLat([19.800265, 47.506415])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el5.properties.title}</h6>
          <h5>${el5.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el5.addEventListener('click', () => {
      this.chart5 = !this.chart5;
    }
    );

    const el6 = document.createElement('div');
    el6.className = 'marker6';
    el6.style.backgroundImage = 'url(images/weather-icon.png)';
    el6.style.width = '30px';
    el6.style.height = '45px';
    el6.style.backgroundSize = 'cover';
    el6.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Metos II.'
    };
    new MapboxGl.Marker(el6)
      .setLngLat([17.254939, 47.900743])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el6.properties.title}</h6>
          <h5>${el6.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el6.addEventListener('click', () => {
      this.chart6 = !this.chart6;
    }
    );

    const el7 = document.createElement('div');
    el7.className = 'marker7';
    el7.style.backgroundImage = 'url(images/weather-icon.png)';
    el7.style.width = '30px';
    el7.style.height = '45px';
    el7.style.backgroundSize = 'cover';
    el7.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Metos III.'
    };
    new MapboxGl.Marker(el7)
      .setLngLat([17.266825, 47.891959])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el7.properties.title}</h6>
          <h5>${el7.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el7.addEventListener('click', () => {
      this.chart7 = !this.chart7;
    }
    );

    const el8 = document.createElement('div');
    el8.className = 'marker8';
    el8.style.backgroundImage = 'url(images/weather-icon.png)';
    el8.style.width = '30px';
    el8.style.height = '45px';
    el8.style.backgroundSize = 'cover';
    el8.properties = {
      title: 'Meteorológiai Állomás',
      description: 'Metos IV.'
    };
    new MapboxGl.Marker(el8)
      .setLngLat([20.754713, 47.182333])
      .setPopup(new MapboxGl.Popup({ offset: 25 })// add popups
        .setHTML(
          `<h6>${el8.properties.title}</h6>
          <h5>${el8.properties.description}</h5>`
          //<p>${el3.properties.video}<p>
        ))
      .addTo(this.map);
    el8.addEventListener('click', () => {
      this.chart8 = !this.chart8;
    }
    );

    
    this.initData();
    this.createConnection();
    this.doSubscribe("smartfarm_gps_tracker/#", 0);
    console.log("marker",document.getElementById("liveStreamBtn"))

  },
  methods: {
/*     toggleChart2(){
      console.log("Fos")
    this.chart2 = !this.chart2;
    }, */

    logOut() {
      this.$router.push("../login");
    },
    forgo22() {
      for (let elem of this.vetes22) {
        this.map.setPaintProperty(elem.id, 'fill-extrusion-color', elem.color)
      }
    },
    forgo23() {
      for (let elem of this.vetes23) {
        this.map.setPaintProperty(elem.id, 'fill-extrusion-color', elem.color)
      }
    },
    makeItBig() {
      if (this.big == false) {
        document.getElementById("sensor").width = 1200;
        document.getElementById("sensor").height = 750;
        this.big = true;
      } else {
        document.getElementById("sensor").width = 660;
        document.getElementById("sensor").height = 440;
        this.big = false;
      }
    },
    goBack() {
      this.$router.push("/");
      location.reload();
    },
    async threeModel1() {
      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";
      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";
        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;
      camera.position.set(0, 10, 0);
      camera.rotation.y = Math.PI / 4;
      camera.lookAt(0, 0, 0);
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");
      scene.background = texture;
      const light = new THREE.PointLight(0xffffff, 1, 10000);
      light.position.set(0, 10, 1);
      scene.add(light);
      const mtlLoader = new MTLLoader();
      mtlLoader.load("models/simplified_farm.mtl", (materials) => {
        materials.preload();
        const loader = new OBJLoader();
        loader.setMaterials(materials);
        loader.load("models/simplified_farm.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      camera.position.z = 1;
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    },
    async threeModel2() {
      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";
      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";
        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;
      camera.position.set(-53, -63, 199);
      camera.rotation.y = Math.PI;
      camera.lookAt(0, 10, 0);
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");
      scene.background = texture;
      const light = new THREE.PointLight(0xffffff, 1, 90000);
      light.position.set(0, 10, 1);
      scene.add(light);
      const light2 = new THREE.PointLight(0xffffff, 1, 90000);
      light.position.set(0, 1, 0);
      scene.add(light2);
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 1, 0);
      scene.add(directionalLight);
      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(0, 5, 0);
      scene.add(pointLight);
      const spotLight = new THREE.SpotLight(
        0xffffff,
        1,
        100,
        Math.PI / 4,
        0.5,
        1
      );
      spotLight.position.set(0, 2, 5);
      scene.add(spotLight);
      const mtlLoader = new MTLLoader();
      mtlLoader.load("models/muhely.mtl", (materials) => {
        materials.preload();
        const loader = new OBJLoader();
        loader.setMaterials(materials);
        loader.load("models/muhely.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    },
    hello() {
      this.map.flyTo({ center: [17.23, 47.88], zoom: 12 });
    },
    flyto1() {
      this.map.flyTo({ center: [17.2301472, 47.92887939], zoom: 14 });
    },
    flyto2() {
      this.map.flyTo({ center: [17.24601753, 47.90637716], zoom: 14 });
    },
    flyto3() {
      this.map.flyTo({ center: [17.26039985, 47.89721701], zoom: 15 });
    },

    flyto4() {
      this.map.flyTo({ center: [17.25432333, 47.90266093], zoom: 15 });
    },
    flyto5() {
      this.map.flyTo({ center: [17.2534753, 47.8970667], zoom: 14 });
    },
    flyto6() {
      this.map.flyTo({ center: [17.26048135, 47.89696476], zoom: 14 });
    },
    flyto7() {
      this.map.flyTo({ center: [17.2546698, 47.89058444], zoom: 14 });
    },
    flyto8() {
      this.map.flyTo({ center: [17.25501481, 47.88924795], zoom: 15 });
    },
    flyto9() {
      this.map.flyTo({ center: [17.2524617, 47.88989093], zoom: 15 });
    },
    flyto10() {
      this.map.flyTo({ center: [17.26659465, 47.89848449], zoom: 14 });
    },
    flyto11() {
      this.map.flyTo({ center: [17.26450595, 47.89712499], zoom: 14 });
    },
    flyto12() {
      this.map.flyTo({ center: [17.26393414, 47.89369554], zoom: 16 });
    },
    flyto13() {
      this.map.flyTo({ center: [17.2608433, 47.89701035], zoom: 15 });
    },

    flyto14() {
      this.map.flyTo({ center: [17.25762388, 47.90369167], zoom: 14 });
    },
    flyto15() {
      this.map.flyTo({ center: [17.26165979, 47.9005317], zoom: 14 });
    },
    flyto16() {
      this.map.flyTo({ center: [17.26944425, 47.89421613], zoom: 14 });
    },
    flyto17() {
      this.map.flyTo({ center: [17.27085329, 47.89138859], zoom: 18 });
    },
    flyto18() {
      this.map.flyTo({ center: [17.26569183, 47.89129314], zoom: 16 });
    },
    flyto19() {
      this.map.flyTo({ center: [17.26569183, 47.89129314], zoom: 16 });
    },
    flyto20() {
      this.map.flyTo({ center: [17.27126538, 47.89818228], zoom: 14 });
    },
    flyto21() {
      this.map.flyTo({ center: [17.27969129, 47.89548557], zoom: 14 });
    },
    flyto22() {
      this.map.flyTo({ center: [17.27572806, 47.905757], zoom: 14 });
    },
    flyto23() {
      this.map.flyTo({ center: [17.27267301, 47.90604585], zoom: 14 });
    },
    flyto24() {
      this.map.flyTo({ center: [17.23403118, 47.8434104], zoom: 13 });
    },
    addPolygon101() {
      this.is101 = true;
      this.map.addSource("101", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.24075036, 47.92353026],
                [17.24057287, 47.92347058],
                [17.2363473, 47.92915847],
                [17.23706068, 47.92939324],
                [17.23733874, 47.92950984],
                [17.23747555, 47.92929695],
                [17.23819504, 47.92811156],
                [17.23824553, 47.92797493],
                [17.23824216, 47.92788291],
                [17.23822076, 47.92779059],
                [17.23823996, 47.92775676],
                [17.23830652, 47.92772962],
                [17.2384083, 47.92769174],
                [17.23851995, 47.9275877],
                [17.23932492, 47.92625734],
                [17.24011383, 47.92488397],
                [17.24050183, 47.924156],
                [17.24063443, 47.92385289],
                [17.24071996, 47.92362055],
                [17.24075036, 47.92353026]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "101-1_1",
        type: "fill-extrusion",
        source: "101",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon102() {
      this.is102 = true;
      this.map.addSource("102", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23756828, 47.92237614],
                [17.23609271, 47.92182021],
                [17.23431194, 47.92117599],
                [17.23004152, 47.92684124],
                [17.23136177, 47.92732931],
                [17.23172451, 47.92746061],
                [17.23258272, 47.92776327],
                [17.23334804, 47.92801953],
                [17.23756828, 47.92237614]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "102-1_2",
        type: "fill-extrusion",
        source: "102",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon103() {
      this.is103 = true;
      this.map.addSource("103", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.24057287, 47.92347058],
                [17.23944263, 47.92306628],
                [17.23890188, 47.92285434],
                [17.23756828, 47.92237614],
                [17.23334804, 47.92801953],
                [17.23362258, 47.928121],
                [17.23404709, 47.92828418],
                [17.23464101, 47.92849154],
                [17.23494269, 47.92860622],
                [17.23516214, 47.92870235],
                [17.23561472, 47.92887939],
                [17.2363473, 47.92915847],
                [17.24057287, 47.92347058]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "103-1_3",
        type: "fill-extrusion",
        source: "103",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon104() {
      this.is104 = true;
      this.map.addSource("104", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23431194, 47.92117599],
                [17.2333764, 47.92082201],
                [17.23225032, 47.92044198],
                [17.23118924, 47.92006163],
                [17.23097339, 47.92002208],
                [17.23055871, 47.9200049],
                [17.23021228, 47.9199331],
                [17.22889164, 47.91973944],
                [17.22826097, 47.9204334],
                [17.22723158, 47.92167079],
                [17.22744169, 47.92175473],
                [17.22737558, 47.92183596],
                [17.22724326, 47.92183167],
                [17.2271652, 47.92183459],
                [17.22709736, 47.92184872],
                [17.22702807, 47.92187771],
                [17.22697824, 47.92191329],
                [17.22632628, 47.92257629],
                [17.22635887, 47.92261238],
                [17.22681966, 47.92281626],
                [17.22713074, 47.92290524],
                [17.22736061, 47.9230031],
                [17.22757863, 47.92308534],
                [17.22823631, 47.9233647],
                [17.22824139, 47.92337975],
                [17.22822059, 47.92341339],
                [17.22802148, 47.92366215],
                [17.22775374, 47.92398995],
                [17.22737403, 47.92443681],
                [17.22672619, 47.92423485],
                [17.22575673, 47.92531553],
                [17.22696712, 47.92577495],
                [17.22748311, 47.92594864],
                [17.22859723, 47.9263289],
                [17.2294628, 47.92663751],
                [17.23004152, 47.92684124],
                [17.23431194, 47.92117599]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "104-1_4",
        type: "fill-extrusion",
        source: "104",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon201() {
      this.is201 = true;
      this.map.addSource("201", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.24601753, 47.90637716],
                [17.25014526, 47.90144761],
                [17.24952983, 47.90121632],
                [17.24876046, 47.90091821],
                [17.24845698, 47.9008193],
                [17.24818148, 47.90102634],
                [17.24802214, 47.9011271],
                [17.24740817, 47.90154372],
                [17.2467366, 47.90204241],
                [17.24641484, 47.90231875],
                [17.24545067, 47.90317328],
                [17.24522792, 47.90335198],
                [17.24416418, 47.90426514],
                [17.24369207, 47.90463059],
                [17.24348713, 47.90482473],
                [17.2433103, 47.90496661],
                [17.2430827, 47.90513369],
                [17.2428695, 47.90526035],
                [17.24371959, 47.90556639],
                [17.24437163, 47.90578445],
                [17.24461379, 47.90589057],
                [17.24507711, 47.90603747],
                [17.24601753, 47.90637716]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "201-80_1_lajtapart_bal_oldal_facelia_2023",
        type: "fill-extrusion",
        source: "201",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon202() {
      this.is202 = true;
      this.map.addSource("202", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.24601753, 47.90637716],
                [17.24662885, 47.90658887],
                [17.24768763, 47.90695776],
                [17.2478076, 47.90702135],
                [17.24826306, 47.90719239],
                [17.25231778, 47.90227075],
                [17.25229949, 47.90223764],
                [17.25134563, 47.90188536],
                [17.25014526, 47.90144761],
                [17.24601753, 47.90637716]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "202-80_1_lajtapart_jobb_oldal",
        type: "fill-extrusion",
        source: "202",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon3() {
      this.is3 = true;
      this.map.addSource("3", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25231792, 47.90227079],
                [17.24826324, 47.90719192],
                [17.24911019, 47.9074822],
                [17.25013767, 47.90785293],
                [17.25074738, 47.9080562],
                [17.25085556, 47.90810338],
                [17.25206012, 47.90853403],
                [17.25215499, 47.90855634],
                [17.25222708, 47.90857742],
                [17.25251801, 47.90872116],
                [17.25332928, 47.90899937],
                [17.25343225, 47.9089061],
                [17.25356167, 47.90876366],
                [17.25363184, 47.90867142],
                [17.25369197, 47.90857358],
                [17.25375056, 47.90845082],
                [17.25381304, 47.90829099],
                [17.25387934, 47.90808128],
                [17.25392283, 47.90788153],
                [17.25400118, 47.90750303],
                [17.25407959, 47.9071759],
                [17.25416292, 47.90689628],
                [17.25423527, 47.90670847],
                [17.25443006, 47.90633049],
                [17.25458343, 47.90607203],
                [17.25476405, 47.90579013],
                [17.25505963, 47.90539054],
                [17.25560181, 47.90473357],
                [17.25640613, 47.90377912],
                [17.25621387, 47.90371562],
                [17.25603419, 47.9036655],
                [17.25577455, 47.90357296],
                [17.25531332, 47.90339259],
                [17.25450922, 47.90309067],
                [17.25352873, 47.9027256],
                [17.25231792, 47.90227079]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "3-80_1",
        type: "fill-extrusion",
        source: "3",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon4() {
      this.is4 = true;
      this.map.addSource("4", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.2536064, 47.90030254],
                [17.25520137, 47.90083035],
                [17.25620822, 47.90116022],
                [17.25788199, 47.90170811],
                [17.25815075, 47.90129204],
                [17.2584435, 47.90080895],
                [17.25895361, 47.89991722],
                [17.25942235, 47.89905563],
                [17.25995586, 47.89804416],
                [17.26039985, 47.89721701],
                [17.25911137, 47.89676684],
                [17.25794114, 47.8963553],
                [17.25647395, 47.89583076],
                [17.2557762, 47.89692934],
                [17.25518116, 47.89786317],
                [17.25443141, 47.89903015],
                [17.25401038, 47.89967381],
                [17.2536064, 47.90030254]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "4-OTK",
        type: "fill-extrusion",
        source: "4",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon5() {
      this.is5 = true;
      this.map.addSource("5", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25360586, 47.90030463],
                [17.25329856, 47.90078999],
                [17.25293701, 47.90136507],
                [17.25273757, 47.90170028],
                [17.25301575, 47.90179274],
                [17.25292273, 47.90204807],
                [17.2529302, 47.90212584],
                [17.25302615, 47.90217866],
                [17.25337361, 47.90230894],
                [17.25432333, 47.90266093],
                [17.25482056, 47.90283971],
                [17.25522812, 47.90298185],
                [17.25558875, 47.90309457],
                [17.25582376, 47.90315326],
                [17.2565454, 47.90339913],
                [17.25666232, 47.90343034],
                [17.25701326, 47.90296049],
                [17.2578834, 47.90170815],
                [17.25360586, 47.90030463]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "5-Betongodor",
        type: "fill-extrusion",
        source: "5",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon6111() {
      this.is6111 = true;
      this.map.addSource("6111", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25201098, 47.89928848],
                [17.25040403, 47.89889035],
                [17.25015243, 47.89927801],
                [17.24990434, 47.89962507],
                [17.24973636, 47.89983973],
                [17.24960865, 47.89998224],
                [17.24944771, 47.90013512],
                [17.24908104, 47.90042467],
                [17.24878089, 47.90062357],
                [17.24892515, 47.90069787],
                [17.2492822, 47.90084631],
                [17.24963149, 47.90097189],
                [17.24989479, 47.9010367],
                [17.2501894, 47.90114107],
                [17.25048375, 47.90127524],
                [17.25065495, 47.90134577],
                [17.25201098, 47.89928848]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "6111_Lajtapart_bal_oldal_2023",
        type: "fill-extrusion",
        source: "6111",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon6112() {
      this.is6112 = true;
      this.map.addSource("6112", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25040403, 47.89889035],
                [17.25201098, 47.89928848],
                [17.2534753, 47.8970667],
                [17.25184178, 47.89673059],
                [17.25176236, 47.89686904],
                [17.25161623, 47.89709917],
                [17.2512537, 47.89762394],
                [17.25062073, 47.89855646],
                [17.25040403, 47.89889035]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "6112_Lajtapart_bal_oldal_2023",
        type: "fill-extrusion",
        source: "6112",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon612() {
      this.is612Lp = true;
      this.map.addSource("612", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.2534753, 47.8970667],
                [17.2547449, 47.89516045],
                [17.25376925, 47.89484168],
                [17.25332312, 47.8946761],
                [17.25294458, 47.8945597],
                [17.25280793, 47.89455212],
                [17.25270928, 47.89458015],
                [17.25217935, 47.89596356],
                [17.25206925, 47.89623746],
                [17.25197813, 47.89645145],
                [17.25190436, 47.89661148],
                [17.25184178, 47.89673059],
                [17.2534753, 47.8970667]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "612-Lajtapart_bal_oldal",
        type: "fill-extrusion",
        source: "612",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon62() {
      this.is62 = true;
      this.map.addSource("62", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25248067, 47.90201708],
                [17.25267942, 47.9016846],
                [17.25309415, 47.90103062],
                [17.25381779, 47.89989015],
                [17.25444692, 47.89892482],
                [17.25441196, 47.89891131],
                [17.25442308, 47.89885935],
                [17.25445538, 47.89881778],
                [17.25451268, 47.89881964],
                [17.25569549, 47.89697644],
                [17.25645113, 47.8957759],
                [17.25597239, 47.89561165],
                [17.2553321, 47.89537127],
                [17.2547449, 47.89516045],
                [17.25065495, 47.90134577],
                [17.25163172, 47.90171089],
                [17.25248067, 47.90201708]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "62-Lajtapart_jobb_oldal",
        type: "fill-extrusion",
        source: "62",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon71() {
      this.is71 = true;
      this.map.addSource("71", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25774253, 47.89599727],
                [17.25997503, 47.89266754],
                [17.25931012, 47.89240207],
                [17.25887206, 47.8922343],
                [17.2583244, 47.8930552],
                [17.25788704, 47.89370726],
                [17.25720625, 47.89472499],
                [17.25663251, 47.89559207],
                [17.25715043, 47.8957796],
                [17.25774253, 47.89599727]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "71-K6_muut_melletti_bal_oldal",
        type: "fill-extrusion",
        source: "71",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon72() {
      this.is72 = true;
      this.map.addSource("72", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26048135, 47.89696476],
                [17.26059906, 47.89681632],
                [17.26089863, 47.89642306],
                [17.26160867, 47.89560039],
                [17.26234957, 47.89474145],
                [17.26308863, 47.89388434],
                [17.2620301, 47.89347011],
                [17.26089828, 47.89302566],
                [17.25997503, 47.89266754],
                [17.25774253, 47.89599727],
                [17.25807434, 47.89611395],
                [17.25848812, 47.89626834],
                [17.25912519, 47.89649319],
                [17.25958457, 47.89664421],
                [17.25992313, 47.89678373],
                [17.26048135, 47.89696476]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "72-K6_muut_melletti_jobb_oldal",
        type: "fill-extrusion",
        source: "72",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon81() {
      this.is81 = true;
      this.map.addSource("81", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25874632, 47.89218356],
                [17.2582089, 47.89300202],
                [17.25828522, 47.89305547],
                [17.25872809, 47.89237919],
                [17.25882881, 47.89221272],
                [17.25874632, 47.89218356]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "81-K6_Lajtapart_Komlo",
        type: "fill-extrusion",
        source: "81",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon82() {
      this.is82 = true;
      this.map.addSource("82", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25699939, 47.8949662],
                [17.25768238, 47.89395056],
                [17.25828522, 47.89305547],
                [17.25872814, 47.8923792],
                [17.25877765, 47.89229727],
                [17.25872809, 47.89237919],
                [17.25828522, 47.89305547],
                [17.2582089, 47.89300202],
                [17.25874632, 47.89218356],
                [17.25882876, 47.8922127],
                [17.25882878, 47.89221266],
                [17.25874632, 47.89218356],
                [17.25739486, 47.89165368],
                [17.25602392, 47.89111744],
                [17.2546698, 47.89058444],
                [17.25433834, 47.89046475],
                [17.25429994, 47.89050317],
                [17.25417922, 47.89083386],
                [17.25375195, 47.89193068],
                [17.25332948, 47.89299882],
                [17.25299291, 47.89384584],
                [17.25283118, 47.89420093],
                [17.25294401, 47.89425255],
                [17.25310012, 47.89432548],
                [17.25334099, 47.8944241],
                [17.2543768, 47.89480731],
                [17.25510495, 47.89507156],
                [17.25572622, 47.89528382],
                [17.2559989, 47.89536046],
                [17.25621203, 47.89543255],
                [17.25658397, 47.89557582],
                [17.25699939, 47.8949662]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "82-K6_Lajtapart",
        type: "fill-extrusion",
        source: "82",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon9() {
      this.is9 = true;
      this.map.addSource("9", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.25813765, 47.88834869],
                [17.25800973, 47.88836228],
                [17.25694072, 47.88865297],
                [17.25591369, 47.88899628],
                [17.25570604, 47.88905469],
                [17.25553304, 47.88908633],
                [17.25527588, 47.8891424],
                [17.25513432, 47.88918855],
                [17.25501481, 47.88924795],
                [17.25488489, 47.8893432],
                [17.25480271, 47.88941441],
                [17.25474629, 47.8894906],
                [17.25468983, 47.88959496],
                [17.25456453, 47.88987922],
                [17.25433608, 47.89041463],
                [17.25505218, 47.89069585],
                [17.25587914, 47.89102095],
                [17.25813765, 47.88834869]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "9-Nyulgat",
        type: "fill-extrusion",
        source: "9",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon10() {
      this.is10 = true;
      this.map.addSource("10", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.2640212, 47.89279515],
                [17.26399042, 47.89274376],
                [17.26400402, 47.89269906],
                [17.26406613, 47.89261785],
                [17.26412387, 47.89256849],
                [17.26419636, 47.89253431],
                [17.26425869, 47.89251985],
                [17.26486401, 47.89181342],
                [17.26556919, 47.89100564],
                [17.26547387, 47.89095932],
                [17.26536101, 47.89091064],
                [17.26486306, 47.89073613],
                [17.26453691, 47.89062903],
                [17.26376337, 47.89035632],
                [17.26301166, 47.89009472],
                [17.26282519, 47.89003514],
                [17.26268932, 47.8899864],
                [17.2624617, 47.88989093],
                [17.26146251, 47.88954089],
                [17.26137564, 47.88952225],
                [17.26131004, 47.88949982],
                [17.26119397, 47.88944975],
                [17.26100426, 47.88938004],
                [17.26017185, 47.88908074],
                [17.2591533, 47.88872095],
                [17.25887086, 47.88863021],
                [17.25877031, 47.88859168],
                [17.25859555, 47.88851995],
                [17.25839, 47.88843685],
                [17.25813765, 47.88834869],
                [17.25587914, 47.89102095],
                [17.25709591, 47.891501],
                [17.25868208, 47.89211561],
                [17.25877219, 47.8921247],
                [17.25883934, 47.892159],
                [17.25891537, 47.89219681],
                [17.2601328, 47.89268043],
                [17.26133951, 47.89315513],
                [17.26270531, 47.89368518],
                [17.26313901, 47.8938375],
                [17.2640212, 47.89279515]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "10-Nyulgat_BIO",
        type: "fill-extrusion",
        source: "10",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon11() {
      this.is11 = true;
      this.map.addSource("11", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26706009, 47.89361854],
                [17.26700246, 47.89365154],
                [17.26688602, 47.89378639],
                [17.26664452, 47.89410177],
                [17.26629542, 47.89459257],
                [17.26560068, 47.89560543],
                [17.26485875, 47.89667946],
                [17.26406628, 47.89785629],
                [17.26466395, 47.89800424],
                [17.26517184, 47.89813661],
                [17.26590878, 47.8983178],
                [17.26659465, 47.89848449],
                [17.26704703, 47.89779616],
                [17.26763568, 47.89693785],
                [17.26818902, 47.89612867],
                [17.26884359, 47.89516802],
                [17.26932317, 47.89447577],
                [17.26936293, 47.89434683],
                [17.26925784, 47.89430722],
                [17.2684555, 47.89407348],
                [17.26778498, 47.89387462],
                [17.26756616, 47.8937979],
                [17.26727548, 47.89368682],
                [17.26706009, 47.89361854]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "11-Temetoi_polygon",
        type: "fill-extrusion",
        source: "11",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon12() {
      this.is12 = true;
      this.map.addSource("12", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26458707, 47.89284876],
                [17.26454347, 47.89286925],
                [17.26447904, 47.89293783],
                [17.26406529, 47.89351017],
                [17.26516704, 47.89383549],
                [17.26471009, 47.8945103],
                [17.26359214, 47.89418525],
                [17.26301721, 47.89500044],
                [17.26261244, 47.89560231],
                [17.26205932, 47.89642552],
                [17.26152856, 47.89722405],
                [17.26201359, 47.89734891],
                [17.26253611, 47.89748908],
                [17.26301906, 47.89760594],
                [17.26339634, 47.89769377],
                [17.26383882, 47.89781526],
                [17.26400518, 47.89785316],
                [17.26450595, 47.89712499],
                [17.26494832, 47.89647295],
                [17.26556299, 47.89558281],
                [17.26625495, 47.89457657],
                [17.26622662, 47.89456555],
                [17.26621624, 47.89454171],
                [17.26623791, 47.89450983],
                [17.26628023, 47.89448858],
                [17.26632024, 47.8944733],
                [17.26677407, 47.89385179],
                [17.26696393, 47.89362002],
                [17.26640342, 47.89343911],
                [17.26579308, 47.89325343],
                [17.26536208, 47.89310272],
                [17.26496346, 47.89299143],
                [17.26477064, 47.89292947],
                [17.26458707, 47.89284876]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "12-K_3_genezises",
        type: "fill-extrusion",
        source: "12",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon131() {
      this.is131 = true;
      this.map.addSource("131", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26371801, 47.89401927],
                [17.2635921, 47.89418541],
                [17.26471009, 47.8945103],
                [17.26482564, 47.89433966],
                [17.26371801, 47.89401927]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "131-Genezis_tartamkiserlet_1",
        type: "fill-extrusion",
        source: "131",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon132() {
      this.is132 = true;
      this.map.addSource("132", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26381886, 47.89386215],
                [17.26371755, 47.89401933],
                [17.26482576, 47.89433969],
                [17.26492833, 47.89418829],
                [17.26381886, 47.89386215]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "132-Genezis_tartamkiserlet_2",
        type: "fill-extrusion",
        source: "132",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon133() {
      this.is133 = true;
      this.map.addSource("133", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26393414, 47.89369554],
                [17.26381885, 47.89386215],
                [17.26492834, 47.89418844],
                [17.26503946, 47.89402428],
                [17.26393414, 47.89369554]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "133-Genezis_tartamkiserlet_3",
        type: "fill-extrusion",
        source: "133",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon134() {
      this.is134 = true;
      this.map.addSource("134", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26406534, 47.89351017],
                [17.26393414, 47.89369554],
                [17.26503923, 47.89402424],
                [17.26516711, 47.89383549],
                [17.26406534, 47.89351017]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "134-Genezis_tartamkiserlet_4",
        type: "fill-extrusion",
        source: "134",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon14() {
      this.is14 = true;
      this.map.addSource("14", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26450546, 47.89283194],
                [17.26439532, 47.89280029],
                [17.26389724, 47.89335428],
                [17.26329692, 47.89406819],
                [17.26269708, 47.89477231],
                [17.26210263, 47.89545102],
                [17.26193475, 47.8956606],
                [17.26133836, 47.89634886],
                [17.26101365, 47.89676292],
                [17.2608433, 47.89701035],
                [17.2609008, 47.89704498],
                [17.26098429, 47.8970889],
                [17.26137692, 47.89719549],
                [17.26146514, 47.89720613],
                [17.26152139, 47.89714714],
                [17.2617941, 47.89674407],
                [17.26204038, 47.89637184],
                [17.26289562, 47.89510645],
                [17.26378217, 47.89383169],
                [17.26450546, 47.89283194]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "14-K_3_spicc",
        type: "fill-extrusion",
        source: "14",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon15() {
      this.is15 = true;
      this.map.addSource("15", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26632912, 47.89883212],
                [17.26618835, 47.89878998],
                [17.26620543, 47.89875891],
                [17.26510633, 47.89847876],
                [17.263838, 47.89814628],
                [17.26254307, 47.89779972],
                [17.26142313, 47.89750951],
                [17.26128962, 47.89748067],
                [17.26123036, 47.8974568],
                [17.26076469, 47.89731845],
                [17.26068247, 47.89731623],
                [17.26026652, 47.89812488],
                [17.25969751, 47.89923371],
                [17.2590608, 47.90037347],
                [17.25851993, 47.90129005],
                [17.25818777, 47.9018061],
                [17.25768825, 47.90255624],
                [17.25702575, 47.90345183],
                [17.25701998, 47.90349322],
                [17.25701997, 47.90352442],
                [17.25705071, 47.90354864],
                [17.25741394, 47.90366198],
                [17.25751842, 47.90368175],
                [17.25762388, 47.90369167],
                [17.25778774, 47.90368651],
                [17.25881184, 47.90353914],
                [17.2592022, 47.90349887],
                [17.25951765, 47.90347694],
                [17.25979729, 47.90346806],
                [17.26023132, 47.90347397],
                [17.26134916, 47.9034696],
                [17.26157974, 47.90348008],
                [17.26174799, 47.90349579],
                [17.26275031, 47.90362442],
                [17.26305966, 47.90365164],
                [17.26326725, 47.90335721],
                [17.26375748, 47.90262944],
                [17.26435526, 47.90172345],
                [17.26471281, 47.90120385],
                [17.26483863, 47.90100203],
                [17.265005, 47.90076796],
                [17.26536632, 47.90024246],
                [17.26555965, 47.89999036],
                [17.26583021, 47.89956712],
                [17.26606119, 47.89923735],
                [17.26621881, 47.89900079],
                [17.26623761, 47.89893099],
                [17.26628114, 47.89890019],
                [17.26632912, 47.89883212]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "15-K_3_versenyforgo",
        type: "fill-extrusion",
        source: "15",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon16() {
      this.is16 = true;
      this.map.addSource("16", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26639557, 47.89887687],
                [17.26636789, 47.89891433],
                [17.26590299, 47.89955588],
                [17.26561095, 47.90001012],
                [17.26489708, 47.90101508],
                [17.26478616, 47.90118772],
                [17.26425366, 47.90197108],
                [17.26373309, 47.9027521],
                [17.2631128, 47.90368271],
                [17.26372212, 47.90381017],
                [17.26424804, 47.90390721],
                [17.26510679, 47.90407775],
                [17.26578306, 47.9042435],
                [17.26654611, 47.90443777],
                [17.26749852, 47.90468135],
                [17.26802847, 47.90485431],
                [17.26862295, 47.90509099],
                [17.26936429, 47.90538451],
                [17.26941095, 47.90541901],
                [17.2694452, 47.90545627],
                [17.26953439, 47.90567782],
                [17.26959486, 47.90580237],
                [17.26965041, 47.90587704],
                [17.2697266, 47.90594545],
                [17.27049105, 47.90631756],
                [17.27056166, 47.90629757],
                [17.27078058, 47.90618812],
                [17.27101486, 47.90610012],
                [17.27119342, 47.90602626],
                [17.2712818, 47.90597219],
                [17.27138597, 47.9058742],
                [17.2714753, 47.90574794],
                [17.27165979, 47.9055317],
                [17.27189798, 47.90519081],
                [17.27202364, 47.90499102],
                [17.27206094, 47.90490392],
                [17.27212439, 47.90483283],
                [17.2722162, 47.90473205],
                [17.27254968, 47.90426763],
                [17.27291461, 47.90373282],
                [17.27323204, 47.90325179],
                [17.27365183, 47.90261161],
                [17.27394836, 47.90217628],
                [17.27424018, 47.9017373],
                [17.27457482, 47.90124561],
                [17.27435912, 47.90117557],
                [17.27426699, 47.90114047],
                [17.27403114, 47.90098624],
                [17.27393792, 47.90092507],
                [17.27380895, 47.90085521],
                [17.27358661, 47.9007464],
                [17.27336111, 47.90064969],
                [17.27281862, 47.900436],
                [17.27260291, 47.90037116],
                [17.27114931, 47.90001428],
                [17.26936559, 47.89956612],
                [17.26798089, 47.89921367],
                [17.26686488, 47.89893663],
                [17.26671194, 47.89891768],
                [17.26663945, 47.89888995],
                [17.26657273, 47.89887057],
                [17.26652058, 47.89886146],
                [17.26645795, 47.89886292],
                [17.26639557, 47.89887687]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "16-Kokenyes",
        type: "fill-extrusion",
        source: "16",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon17() {
      this.is17 = true;
      this.map.addSource("17", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.27140556, 47.89264671],
                [17.27014065, 47.89207887],
                [17.26907309, 47.89159477],
                [17.26899496, 47.89157513],
                [17.26876374, 47.89180885],
                [17.26824368, 47.89234387],
                [17.26810131, 47.89248007],
                [17.26782081, 47.89278083],
                [17.2674026, 47.89321977],
                [17.2672557, 47.8934007],
                [17.26719354, 47.89348747],
                [17.26720628, 47.89351928],
                [17.26722518, 47.89354458],
                [17.26811633, 47.89383096],
                [17.26839164, 47.89393715],
                [17.26877806, 47.89405361],
                [17.26939681, 47.89423637],
                [17.26944425, 47.89421613],
                [17.26948397, 47.89419486],
                [17.26953508, 47.89419472],
                [17.26960752, 47.89421786],
                [17.26962212, 47.8942361],
                [17.26988297, 47.89431391],
                [17.27025302, 47.89445704],
                [17.27035339, 47.89449743],
                [17.27051865, 47.89454974],
                [17.27067979, 47.89458346],
                [17.27077914, 47.89457927],
                [17.27089048, 47.89454893],
                [17.27095313, 47.89414264],
                [17.27100848, 47.89372217],
                [17.27122013, 47.89299533],
                [17.27124633, 47.89293374],
                [17.27135333, 47.89273089],
                [17.27140556, 47.89264671]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "17-K_2_szeruskerti",
        type: "fill-extrusion",
        source: "17",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon18() {
      this.is18 = true;
      this.map.addSource("18", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.27014065, 47.89207887],
                [17.27140588, 47.89264677],
                [17.27178342, 47.89209886],
                [17.27180397, 47.89196545],
                [17.27175249, 47.89187972],
                [17.27163402, 47.89175862],
                [17.27100498, 47.89145164],
                [17.27085329, 47.89138859],
                [17.27079467, 47.89138084],
                [17.27074372, 47.89138441],
                [17.27069931, 47.89139502],
                [17.27049959, 47.89160467],
                [17.27039771, 47.89173159],
                [17.27031919, 47.89185272],
                [17.27026266, 47.89194426],
                [17.27014065, 47.89207887]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "18-K_2_rombolo",
        type: "fill-extrusion",
        source: "18",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon19() {
      this.is19 = true;
      this.map.addSource("19", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26786179, 47.89113439],
                [17.26703355, 47.89076258],
                [17.26693606, 47.8908178],
                [17.26676447, 47.8907397],
                [17.26681609, 47.89067817],
                [17.26665673, 47.89060264],
                [17.26604584, 47.89121929],
                [17.26554379, 47.89173818],
                [17.26515798, 47.89215626],
                [17.2647552, 47.89260236],
                [17.26463046, 47.89274149],
                [17.26523495, 47.89295953],
                [17.26540482, 47.89301636],
                [17.26555935, 47.8930539],
                [17.265852, 47.89312949],
                [17.26605458, 47.89319133],
                [17.26697853, 47.89350741],
                [17.26705946, 47.89351541],
                [17.26731862, 47.89322144],
                [17.2677938, 47.89271956],
                [17.26838095, 47.89212034],
                [17.26880491, 47.89168022],
                [17.26881653, 47.89165325],
                [17.26880759, 47.89162758],
                [17.26791573, 47.8912327],
                [17.26782826, 47.89119175],
                [17.26786179, 47.89113439]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "19-K_2_tyukolak",
        type: "fill-extrusion",
        source: "19",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon20() {
      this.is20 = true;
      this.map.addSource("20", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.26662312, 47.89056563],
                [17.2665831, 47.89053183],
                [17.26646099, 47.89048508],
                [17.26640219, 47.89048398],
                [17.26636965, 47.8905111],
                [17.26616434, 47.89074208],
                [17.26599995, 47.89095154],
                [17.26594616, 47.89103361],
                [17.26588395, 47.89109383],
                [17.26569183, 47.89129314],
                [17.26537894, 47.89165654],
                [17.26493371, 47.89217554],
                [17.26489112, 47.8922538],
                [17.26478073, 47.89235201],
                [17.26450526, 47.89267229],
                [17.26458935, 47.89270039],
                [17.26503015, 47.89222103],
                [17.26579395, 47.89141909],
                [17.26627488, 47.89092596],
                [17.26662312, 47.89056563]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "20-k_2_spicc",
        type: "fill-extrusion",
        source: "20",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon21() {
      this.is21 = true;
      this.map.addSource("21", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.27626996, 47.89455824],
                [17.27626988, 47.8945584],
                [17.27621068, 47.89457339],
                [17.27617343, 47.89460858],
                [17.2760786, 47.89477413],
                [17.27599333, 47.89496232],
                [17.27597896, 47.89502445],
                [17.2759316, 47.89508152],
                [17.2756352, 47.89564517],
                [17.27559527, 47.89574809],
                [17.2755918, 47.89580325],
                [17.27561367, 47.89584919],
                [17.27565053, 47.89589318],
                [17.27600422, 47.89617411],
                [17.27600739, 47.89620432],
                [17.27599678, 47.89626157],
                [17.27585168, 47.89675708],
                [17.27578561, 47.89694605],
                [17.27593188, 47.89699467],
                [17.27628852, 47.89707205],
                [17.27705907, 47.8971981],
                [17.27726759, 47.8972562],
                [17.27748897, 47.89734819],
                [17.27775895, 47.89746619],
                [17.27804405, 47.89756387],
                [17.27910124, 47.89781703],
                [17.27923321, 47.89785514],
                [17.27978859, 47.89801427],
                [17.28008459, 47.89808434],
                [17.28023818, 47.89810616],
                [17.28067719, 47.8981497],
                [17.28126538, 47.89818228],
                [17.28197165, 47.89820825],
                [17.28274177, 47.89823149],
                [17.28300838, 47.8982919],
                [17.28330321, 47.89838971],
                [17.28368638, 47.89849832],
                [17.28388633, 47.89852754],
                [17.2839637, 47.89851047],
                [17.28400317, 47.89844421],
                [17.28403723, 47.89833526],
                [17.28410551, 47.89793635],
                [17.28425277, 47.8974684],
                [17.28435337, 47.89725122],
                [17.284345, 47.89720103],
                [17.28431044, 47.89715843],
                [17.28421289, 47.89710992],
                [17.2834151, 47.89684866],
                [17.28170569, 47.89629804],
                [17.28006991, 47.89577551],
                [17.27938081, 47.89556394],
                [17.27870709, 47.8953676],
                [17.27794755, 47.89512985],
                [17.27736165, 47.89494263],
                [17.27692904, 47.89480389],
                [17.27656108, 47.89466367],
                [17.27628398, 47.89456298],
                [17.27626996, 47.89455824]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "21-Loter",
        type: "fill-extrusion",
        source: "21",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon22() {
      this.is22 = true;
      this.map.addSource("22", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.28095948, 47.89250861],
                [17.28066772, 47.89242267],
                [17.28013707, 47.89223351],
                [17.27988567, 47.89215773],
                [17.27936325, 47.89197379],
                [17.27930102, 47.89197932],
                [17.27924636, 47.8919999],
                [17.27919364, 47.89203749],
                [17.27887, 47.89235498],
                [17.27873462, 47.89252691],
                [17.27855792, 47.89268672],
                [17.27839935, 47.89280354],
                [17.27796947, 47.89317576],
                [17.27792106, 47.89321275],
                [17.27781774, 47.89329169],
                [17.27757257, 47.89356242],
                [17.27743507, 47.89367428],
                [17.27698127, 47.89400273],
                [17.27688207, 47.89410681],
                [17.27688832, 47.89416323],
                [17.27691524, 47.8942033],
                [17.2770926, 47.89430974],
                [17.27712549, 47.89435309],
                [17.27711646, 47.89439704],
                [17.27695899, 47.89453096],
                [17.27703514, 47.89457078],
                [17.27724935, 47.89467229],
                [17.27746763, 47.89475052],
                [17.27790566, 47.89488066],
                [17.27840275, 47.89503947],
                [17.27868245, 47.89513967],
                [17.27908325, 47.8952694],
                [17.27969129, 47.89548557],
                [17.2797997, 47.89552411],
                [17.28007413, 47.89560555],
                [17.28112098, 47.89596433],
                [17.28273652, 47.89649292],
                [17.28329513, 47.89667372],
                [17.28357536, 47.89676441],
                [17.28518881, 47.89728395],
                [17.28643937, 47.89768703],
                [17.28664861, 47.89775284],
                [17.28733813, 47.89729821],
                [17.28809652, 47.89680908],
                [17.28829115, 47.89668355],
                [17.28926995, 47.89605648],
                [17.29018445, 47.89548342],
                [17.29013447, 47.89544895],
                [17.28991234, 47.89537486],
                [17.28925778, 47.89517654],
                [17.28863375, 47.89499987],
                [17.28814583, 47.89484754],
                [17.28784748, 47.89472887],
                [17.28755111, 47.89462784],
                [17.28710335, 47.89449553],
                [17.28679235, 47.8943888],
                [17.28653951, 47.8943058],
                [17.28605063, 47.89417529],
                [17.28561874, 47.89403552],
                [17.28528153, 47.89392638],
                [17.28444883, 47.8936479],
                [17.28357799, 47.89336126],
                [17.28289283, 47.89316404],
                [17.28271226, 47.89310795],
                [17.28242399, 47.89299907],
                [17.28213247, 47.89289786],
                [17.28123106, 47.89258489],
                [17.28095948, 47.89250861]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "22-Borjunevelo",
        type: "fill-extrusion",
        source: "22",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon23() {
      this.is23 = true;
      this.map.addSource("23", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.27503232, 47.90138247],
                [17.27503314, 47.90138293],
                [17.27495897, 47.90148029],
                [17.27488594, 47.90155593],
                [17.27473414, 47.90178259],
                [17.27460878, 47.90199262],
                [17.27457509, 47.90208769],
                [17.27454916, 47.9021739],
                [17.27452766, 47.90223399],
                [17.27449556, 47.9022909],
                [17.27444824, 47.90234292],
                [17.27437902, 47.9024051],
                [17.27431812, 47.90245405],
                [17.27422252, 47.90255859],
                [17.2736003, 47.90342101],
                [17.27325684, 47.90392502],
                [17.27286402, 47.90449373],
                [17.27243282, 47.90513323],
                [17.27245125, 47.90517483],
                [17.27256017, 47.90522888],
                [17.27289632, 47.90536332],
                [17.27314029, 47.90543502],
                [17.27337828, 47.90548604],
                [17.27354765, 47.90549169],
                [17.27373634, 47.90547198],
                [17.27420781, 47.90537962],
                [17.27452448, 47.90530321],
                [17.27463876, 47.90528896],
                [17.27475808, 47.9052862],
                [17.27512368, 47.90531008],
                [17.27590904, 47.90543117],
                [17.27590885, 47.90543088],
                [17.27655037, 47.90550075],
                [17.27698432, 47.90558167],
                [17.27754891, 47.90571082],
                [17.27772806, 47.905757],
                [17.27790993, 47.90582446],
                [17.2787706, 47.90615078],
                [17.27943767, 47.90644353],
                [17.27994667, 47.90662638],
                [17.28035953, 47.90670493],
                [17.28063735, 47.90676177],
                [17.28089285, 47.90684868],
                [17.28130779, 47.90695577],
                [17.28154607, 47.90695957],
                [17.28187863, 47.90677669],
                [17.28182305, 47.90671328],
                [17.28181279, 47.90667026],
                [17.28181539, 47.90661186],
                [17.2818065, 47.90656844],
                [17.28177943, 47.90652352],
                [17.2815686, 47.90633138],
                [17.28027608, 47.90517841],
                [17.2792677, 47.90427961],
                [17.27914826, 47.90418717],
                [17.27905338, 47.90409216],
                [17.27856429, 47.90364691],
                [17.27844237, 47.9035496],
                [17.27830912, 47.90341874],
                [17.27743522, 47.90264009],
                [17.2773203, 47.90253864],
                [17.27717232, 47.90242133],
                [17.2770257, 47.90232729],
                [17.27686892, 47.90224101],
                [17.27585306, 47.90176359],
                [17.27570023, 47.90169326],
                [17.27548564, 47.90159306],
                [17.27535126, 47.9015311],
                [17.27517625, 47.90145026],
                [17.27503232, 47.90138247]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "23-Bugabanyai",
        type: "fill-extrusion",
        source: "23",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon24() {
      this.is24 = true;
      this.map.addSource("24", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.27525636, 47.90128836],
                [17.27570695, 47.90152444],
                [17.27665956, 47.90195393],
                [17.27702733, 47.90213464],
                [17.27717076, 47.90220492],
                [17.27727205, 47.90226603],
                [17.2773883, 47.90235561],
                [17.27857469, 47.90341904],
                [17.27992025, 47.9046214],
                [17.28141436, 47.90596205],
                [17.2826287, 47.90704901],
                [17.28265228, 47.9070206],
                [17.28269112, 47.90689346],
                [17.28270212, 47.90672443],
                [17.28267301, 47.90604585],
                [17.28259218, 47.90596438],
                [17.28259688, 47.90591217],
                [17.28266064, 47.90590006],
                [17.28275465, 47.90547746],
                [17.28278143, 47.90528364],
                [17.28281392, 47.90490064],
                [17.28280904, 47.90415468],
                [17.28281231, 47.90378033],
                [17.282894, 47.90293861],
                [17.28285513, 47.90266654],
                [17.28283341, 47.90228298],
                [17.28283352, 47.90222943],
                [17.28285518, 47.90206233],
                [17.28288915, 47.90176868],
                [17.28290512, 47.90161967],
                [17.28291932, 47.9014111],
                [17.28293245, 47.90106257],
                [17.28297261, 47.90074647],
                [17.28302457, 47.9005028],
                [17.28307588, 47.90013956],
                [17.28276406, 47.90015875],
                [17.2819283, 47.9002492],
                [17.2806602, 47.90039873],
                [17.27993627, 47.90046268],
                [17.2783125, 47.90064889],
                [17.27801209, 47.90067547],
                [17.2776628, 47.90072669],
                [17.27751766, 47.90074463],
                [17.27723382, 47.90075713],
                [17.27618395, 47.90084796],
                [17.27569523, 47.90089285],
                [17.2754981, 47.90091883],
                [17.2754432, 47.9010404],
                [17.27525636, 47.90128836]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "24-Kapolnai",
        type: "fill-extrusion",
        source: "24",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon25() {
      this.is25 = true;
      this.map.addSource("25", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23318507, 47.83589728],
                [17.23327341, 47.83585381],
                [17.23337709, 47.83578778],
                [17.23444793, 47.83492958],
                [17.23540018, 47.83416801],
                [17.2365295, 47.83325817],
                [17.2374812, 47.83250222],
                [17.2375746, 47.83242349],
                [17.23863072, 47.83160467],
                [17.23868301, 47.83155522],
                [17.23869874, 47.8315267],
                [17.2386799, 47.83149646],
                [17.23777741, 47.8310086],
                [17.23683378, 47.83050757],
                [17.23584714, 47.82997982],
                [17.23571647, 47.82993029],
                [17.23564361, 47.82993243],
                [17.2355466, 47.8299692],
                [17.23545506, 47.83002375],
                [17.23446926, 47.8307835],
                [17.2342029, 47.83098088],
                [17.23393097, 47.83117275],
                [17.23345417, 47.83154248],
                [17.23334556, 47.83161607],
                [17.23325853, 47.83165446],
                [17.23312551, 47.83168611],
                [17.23299091, 47.83173727],
                [17.2321527, 47.83232805],
                [17.2311695, 47.83301509],
                [17.23112334, 47.83306237],
                [17.23108821, 47.83312202],
                [17.23106374, 47.83319051],
                [17.23104231, 47.83324991],
                [17.23100348, 47.83330672],
                [17.23085039, 47.83343165],
                [17.23075033, 47.83350485],
                [17.23066798, 47.83356431],
                [17.23006309, 47.83398728],
                [17.2300242, 47.83403084],
                [17.23001383, 47.83405792],
                [17.23001894, 47.83408665],
                [17.23141759, 47.83490701],
                [17.23154812, 47.83497462],
                [17.23173822, 47.83505448],
                [17.23180689, 47.83508762],
                [17.23229035, 47.83536926],
                [17.23250694, 47.83548352],
                [17.2326104, 47.83555775],
                [17.23279335, 47.83566742],
                [17.23311904, 47.83586478],
                [17.23317616, 47.83589445],
                [17.23318507, 47.83589728]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "25-Biotechnologia_jobb_oldal",
        type: "fill-extrusion",
        source: "25",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon26() {
      this.is26 = true;
      this.map.addSource("26", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23336865, 47.83599694],
                [17.23349022, 47.83608675],
                [17.23372432, 47.83623986],
                [17.23395372, 47.8363718],
                [17.23406909, 47.83642236],
                [17.23413324, 47.83645088],
                [17.23430709, 47.83656373],
                [17.23445431, 47.83665083],
                [17.23462342, 47.83677125],
                [17.23485376, 47.83692011],
                [17.23530977, 47.83719256],
                [17.2357102, 47.83741078],
                [17.23627915, 47.83774847],
                [17.2363531, 47.83777851],
                [17.23671328, 47.83755639],
                [17.23754359, 47.83690235],
                [17.23834479, 47.83627261],
                [17.23928049, 47.83554511],
                [17.24051148, 47.83458072],
                [17.24212034, 47.83332271],
                [17.24211638, 47.83331422],
                [17.24167997, 47.83306349],
                [17.24079774, 47.83258717],
                [17.23925535, 47.83176598],
                [17.23908282, 47.83168313],
                [17.23892379, 47.83161319],
                [17.23888189, 47.83162043],
                [17.23820647, 47.83215924],
                [17.23814369, 47.83222766],
                [17.23813154, 47.83227123],
                [17.23815422, 47.83230802],
                [17.23818653, 47.8323393],
                [17.2387826, 47.83276123],
                [17.23851704, 47.83300846],
                [17.23846065, 47.83307834],
                [17.23844333, 47.83312015],
                [17.23845056, 47.83318113],
                [17.2384925, 47.83326246],
                [17.23859536, 47.83343494],
                [17.23840967, 47.83362048],
                [17.238148, 47.83388627],
                [17.23803929, 47.83400002],
                [17.2379655, 47.83405683],
                [17.23783073, 47.83397118],
                [17.23762891, 47.83383194],
                [17.23679368, 47.8333513],
                [17.23675844, 47.8333507],
                [17.23671055, 47.83336515],
                [17.23665159, 47.83339864],
                [17.2360143, 47.83391107],
                [17.23578032, 47.83411825],
                [17.23569436, 47.8341888],
                [17.2356503, 47.83421834],
                [17.2349712, 47.8347422],
                [17.23492291, 47.83478982],
                [17.23489461, 47.8348091],
                [17.23424952, 47.83528976],
                [17.23343573, 47.8359366],
                [17.23336865, 47.83599694]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "26-Biotechnologia_bal_oldal_(Tanyas)",
        type: "fill-extrusion",
        source: "26",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon27() {
      this.is27 = true;
      this.map.addSource("27", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.2334286, 47.84004168],
                [17.23362451, 47.83989958],
                [17.23411872, 47.8394921],
                [17.23415985, 47.83944894],
                [17.23434213, 47.83930757],
                [17.23486186, 47.83888089],
                [17.23585979, 47.83805884],
                [17.23593092, 47.83798116],
                [17.23591811, 47.83794975],
                [17.23587099, 47.83791339],
                [17.2354889, 47.83766921],
                [17.2348242, 47.83728316],
                [17.23417565, 47.8369088],
                [17.23328938, 47.83635818],
                [17.23317248, 47.83629024],
                [17.2330988, 47.83627142],
                [17.23305295, 47.83627697],
                [17.23298951, 47.83629926],
                [17.23225857, 47.8368683],
                [17.23160819, 47.83738968],
                [17.23051144, 47.83825287],
                [17.23047468, 47.83830039],
                [17.23049338, 47.83832466],
                [17.23057589, 47.83837403],
                [17.23167918, 47.83902378],
                [17.23241423, 47.83945836],
                [17.23334896, 47.84000017],
                [17.2334286, 47.84004168]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "27-Biotechnologia_lucernas",
        type: "fill-extrusion",
        source: "27",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon28() {
      this.is28 = true;
      this.map.addSource("28", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23370873, 47.84018244],
                [17.23375129, 47.84023626],
                [17.23383328, 47.84029005],
                [17.23483661, 47.84086954],
                [17.23628989, 47.84169192],
                [17.23757303, 47.84243166],
                [17.23766454, 47.84245948],
                [17.23783746, 47.84249661],
                [17.23788283, 47.84253974],
                [17.23793547, 47.84260994],
                [17.23805433, 47.84270321],
                [17.23887592, 47.84316301],
                [17.23896839, 47.843184],
                [17.24104337, 47.84436864],
                [17.24115066, 47.84441381],
                [17.24123366, 47.84437034],
                [17.24135023, 47.84428465],
                [17.24137973, 47.84427242],
                [17.2414071, 47.84426893],
                [17.24175552, 47.84443147],
                [17.24189546, 47.84449839],
                [17.24229075, 47.84470374],
                [17.242359, 47.84470947],
                [17.24302035, 47.84417704],
                [17.24367199, 47.84364735],
                [17.24436247, 47.84309567],
                [17.24481926, 47.84272205],
                [17.24485353, 47.84268051],
                [17.24447546, 47.84246112],
                [17.24438843, 47.8423893],
                [17.24362303, 47.8419607],
                [17.24350311, 47.84191167],
                [17.24275334, 47.84150568],
                [17.24173453, 47.84095752],
                [17.24169294, 47.84094017],
                [17.24126219, 47.84071301],
                [17.24115578, 47.84066659],
                [17.24040031, 47.84027064],
                [17.24016035, 47.84015879],
                [17.24006448, 47.84012246],
                [17.23957618, 47.83987454],
                [17.23942544, 47.83980569],
                [17.23933084, 47.83976146],
                [17.23916426, 47.83966775],
                [17.23895237, 47.83956332],
                [17.23888215, 47.83953525],
                [17.23883477, 47.83951962],
                [17.23877005, 47.83948468],
                [17.23865138, 47.83940929],
                [17.23858681, 47.83937004],
                [17.23822927, 47.83917996],
                [17.2380527, 47.83908881],
                [17.23790269, 47.83900496],
                [17.23719408, 47.83863252],
                [17.23706568, 47.83857187],
                [17.2366632, 47.83835356],
                [17.23653762, 47.8382913],
                [17.23643691, 47.8382618],
                [17.23630819, 47.83825785],
                [17.23618252, 47.83826138],
                [17.23605667, 47.83827068],
                [17.23596695, 47.8382903],
                [17.23582815, 47.83835718],
                [17.23557645, 47.83854754],
                [17.23543843, 47.83864454],
                [17.23499306, 47.83898568],
                [17.2345239, 47.83934788],
                [17.23384675, 47.83988726],
                [17.23377082, 47.83994998],
                [17.23378584, 47.83998593],
                [17.2338297, 47.84004329],
                [17.23384685, 47.84005665],
                [17.23370961, 47.84018156],
                [17.23370873, 47.84018244]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "28-Biotechnologia_dogkut",
        type: "fill-extrusion",
        source: "28",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon29() {
      this.is29 = true;
      this.map.addSource("29", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23619096, 47.84178891],
                [17.23550888, 47.84230854],
                [17.23479487, 47.84285732],
                [17.23408363, 47.84339925],
                [17.23361376, 47.84376188],
                [17.23359841, 47.84379618],
                [17.23360375, 47.84383457],
                [17.23361641, 47.84385585],
                [17.23393174, 47.84403316],
                [17.2342548, 47.84420355],
                [17.23452154, 47.84435968],
                [17.23536245, 47.84481993],
                [17.23548746, 47.84487808],
                [17.23557348, 47.84491631],
                [17.23564338, 47.84496361],
                [17.23576643, 47.84505819],
                [17.23648299, 47.84546882],
                [17.23653129, 47.84548429],
                [17.23657072, 47.8455041],
                [17.23667494, 47.84555969],
                [17.23671674, 47.84556739],
                [17.23676162, 47.8455597],
                [17.23671372, 47.84562277],
                [17.2374379, 47.84603942],
                [17.23793267, 47.84632044],
                [17.23830019, 47.84653254],
                [17.2387669, 47.84678993],
                [17.23934169, 47.84632683],
                [17.23926373, 47.84628453],
                [17.23948583, 47.84611013],
                [17.23955909, 47.84612476],
                [17.2406112, 47.84528551],
                [17.2405236, 47.84522897],
                [17.24129836, 47.84463238],
                [17.24079425, 47.84433631],
                [17.24025796, 47.84403327],
                [17.2394374, 47.84356283],
                [17.23934299, 47.84361632],
                [17.23931565, 47.84351582],
                [17.23852832, 47.84306682],
                [17.2380551, 47.8427841],
                [17.23736957, 47.84239013],
                [17.23638393, 47.8418268],
                [17.23626747, 47.84177788],
                [17.23622137, 47.84178072],
                [17.23619096, 47.84178891]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "29-Biotechnologia_legelo",
        type: "fill-extrusion",
        source: "29",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon30() {
      this.is30 = true;
      this.map.addSource("30", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23347449, 47.84016514],
                [17.23340058, 47.84021676],
                [17.23333302, 47.84026754],
                [17.23257135, 47.84085549],
                [17.23198809, 47.8413077],
                [17.2314132, 47.84175565],
                [17.23080999, 47.8422242],
                [17.23079829, 47.84226163],
                [17.23080255, 47.84230865],
                [17.2314392, 47.84266065],
                [17.23156701, 47.84272819],
                [17.23244849, 47.84321271],
                [17.2331147, 47.84358241],
                [17.23340368, 47.84374796],
                [17.23347057, 47.8437777],
                [17.23351694, 47.84378264],
                [17.23357028, 47.84376331],
                [17.23403118, 47.8434104],
                [17.23408364, 47.84336446],
                [17.23432521, 47.84318391],
                [17.23478493, 47.84282258],
                [17.23481073, 47.84280026],
                [17.23488384, 47.84274984],
                [17.23499749, 47.84266973],
                [17.23556133, 47.84223958],
                [17.23616979, 47.84177545],
                [17.23619635, 47.84174474],
                [17.23619976, 47.841729],
                [17.23617372, 47.84170737],
                [17.23526903, 47.84118947],
                [17.23462223, 47.84081594],
                [17.23357969, 47.84022257],
                [17.23353872, 47.84020167],
                [17.2334923, 47.84019033],
                [17.23347449, 47.84016514]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "30-Biotechnologia_legelo_melletti",
        type: "fill-extrusion",
        source: "30",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    addPolygon31() {
      this.is31 = true;
      this.map.addSource("31", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [17.23340491, 47.84008723],
                [17.23330145, 47.84002276],
                [17.2326788, 47.83965109],
                [17.23184349, 47.83915596],
                [17.23068968, 47.83847801],
                [17.23056642, 47.83841957],
                [17.230447, 47.8383845],
                [17.23038449, 47.83838251],
                [17.23033022, 47.83839947],
                [17.23027706, 47.83842733],
                [17.23019736, 47.83848623],
                [17.229357, 47.83913436],
                [17.22883978, 47.83953533],
                [17.22858314, 47.83974959],
                [17.22760167, 47.84050411],
                [17.22743571, 47.8406427],
                [17.22744673, 47.84069264],
                [17.22753153, 47.84073944],
                [17.22852539, 47.841202],
                [17.22859625, 47.84122353],
                [17.22869038, 47.84125463],
                [17.2287427, 47.84129137],
                [17.2288151, 47.84133673],
                [17.22919015, 47.84151287],
                [17.2295499, 47.84166798],
                [17.22965605, 47.84170441],
                [17.22975791, 47.84173623],
                [17.22979653, 47.84175329],
                [17.22986066, 47.8417994],
                [17.22994345, 47.84186012],
                [17.23001281, 47.84189875],
                [17.23008545, 47.84193143],
                [17.23022554, 47.84197998],
                [17.23029053, 47.84202271],
                [17.23044685, 47.84212788],
                [17.23059677, 47.84219936],
                [17.23070089, 47.84216428],
                [17.23074245, 47.84213597],
                [17.23109132, 47.84187606],
                [17.23114722, 47.84182458],
                [17.23118383, 47.84179635],
                [17.23205263, 47.84112821],
                [17.23214187, 47.84106694],
                [17.23255978, 47.8407554],
                [17.23291895, 47.84046746],
                [17.23312548, 47.84030787],
                [17.23323452, 47.84023494],
                [17.23336799, 47.84012073],
                [17.23340491, 47.84008723]
              ],
            ],
          },
        },
      });
      this.map.addLayer({
        id: "31-Biotechnologia_Lajtamag_melletti",
        type: "fill-extrusion",
        source: "31",
        layout: {},
        paint: {
          "fill-extrusion-color": "#3AB394",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
    },
    allPolygons() {
      this.addPolygon101();
      this.addPolygon102();
      this.addPolygon103();
      this.addPolygon104();
      this.addPolygon201();
      this.addPolygon202();
      this.addPolygon3();
      this.addPolygon4();
      this.addPolygon5();
      this.addPolygon6111();
      this.addPolygon6112();
      this.addPolygon612();
      this.addPolygon62();
      this.addPolygon71();
      this.addPolygon72();
      this.addPolygon81();
      this.addPolygon82();
      this.addPolygon9();
      this.addPolygon10();
      this.addPolygon11();
      this.addPolygon12();
      this.addPolygon131();
      this.addPolygon132();
      this.addPolygon133();
      this.addPolygon134();
      this.addPolygon14();
      this.addPolygon15();
      this.addPolygon16();
      this.addPolygon17();
      this.addPolygon18();
      this.addPolygon19();
      this.addPolygon20();
      this.addPolygon21();
      this.addPolygon22();
      this.addPolygon23();
      this.addPolygon24();
      this.addPolygon25();
      this.addPolygon26();
      this.addPolygon27();
      this.addPolygon28();
      this.addPolygon29();
      this.addPolygon30();
      this.addPolygon31();
    },
    qgis2022() {
      window.open(`${process.env.VUE_APP_API_URL}/v1/qgisfiles?filename=Vetesforgo2022QGIS.zip`);
    },
    qgis2023() {
      window.open(`${process.env.VUE_APP_API_URL}/v1/qgisfiles?filename=Vetesforgo2023QGIS.zip`);
    },
    qgisGenK3() {
      window.open(`${process.env.VUE_APP_API_URL}/v1/qgisfiles?filename=K3GenezisesSmartoktatokert.zip`);
    },
    qgis6Lajta() {
      window.open(`${process.env.VUE_APP_API_URL}/v1/qgisfiles?filename=6Lajtapartkukorica2023.zip`);
    },
    //----------------------------------------MQQT-----------------------------------------------
    initData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleOnReConnect() {
      this.retryTimes += 1;
      if (this.retryTimes > 5) {
        try {
          this.client.end();
          this.initData();
          this.$message.error("Connection maxReconnectTimes limit, stop retry");
        } catch (error) {
          this.$message.error(error.toString());
        }
      }
    },
    createConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        console.log(this.client);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
          });
          this.client.on("message", (topic, message) => {
            //console.log(this.map.getSource('LineString'));
            let parsedMessage = JSON.parse(message.toString());
            //console.log(this.receiveNews, JSON.parse(message.toString()));
            if (topic == "smartfarm_gps_tracker/GPSTracker01") {
              this.map._markers[0].setLngLat([parsedMessage.longitude, parsedMessage.latitude]);
              this.linestring.features[0].geometry.coordinates.unshift([parsedMessage.longitude, parsedMessage.latitude]);
              if (this.linestring.features[0].geometry.coordinates.length > 10) {
                this.linestring.features[0].geometry.coordinates.pop();
              }
            }
            this.map.getSource('LineString').setData(this.linestring);
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
      }
    },
    doSubscribe(topic, qos) {
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
      })
    },
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
        }
      })
    },
    doPublish() {
      const { topic, qos, payload } = this.publish
      this.client.publish(topic, payload, { qos }, error => {
        if (error) {
          console.log('Publish error', error)
        }
      })
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initData()
            console.log('Successfully disconnected!')
          })
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
  },
  unmounted() {
    clearInterval(this.interval);
    this.map.remove();
    this.destroyMQTTConnection();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
  color: white;
  z-index: 1000;
  border: 1px solid grey;
  margin: 10px;
  width: auto;
  background-color: #006346;
  position: relative;
  visibility: always show;
  font-family: myFirstFont;
  display: block;
}

.btn:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

.btn-row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

#offcanvas-close {
  width: 38px;
}

th {
  border: 1px solid grey;
  padding: 5px;
}

td {
  border: 1px solid grey;
  padding: 5px;
  font-family: myFirstFont;
}

#btn3d {
  width: auto;
  font-family: myFirstFont;
}

.active {
  background-color: #006346;
  color: white;
}

.non-active {
  background-color: #013522;
  color: white;
}

#modelContainer1 {
  border: none;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-item {
  object-fit: cover;
}

#back-button {
  position: absolute;
  width: auto;
  left: 10px;
  top: 10px;
  z-index: 100;
}

#info {
  width: 30vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-y: scroll;
  z-index: 100;
  min-width: 430px;
  left: 2vw;
}

#info::-webkit-scrollbar {
  width: 10px;
}

#info::-webkit-scrollbar-track {
  background-color: transparent;
}

#info::-webkit-scrollbar-thumb {
  background-color: #3AB394;
}

#model {
  width: 70vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

@font-face {
  font-family: myFirstFont;
  src: url(../../public/Roboto-Light.ttf);
}

#map {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

#offcanvasExample1 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample2 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample3 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample4 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample5 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample6 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample7 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample8 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample9 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample10 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample11 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample12 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample13 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample14 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample15 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample16 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample17 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample18 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample19 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample20 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample21 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample22 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample23 {
  width: 25%;
  background-color: #013522;
  color: white;
}

#offcanvasExample24 {
  width: 25%;
  background-color: #013522;
  color: white;
}

canvas {
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#dummyBlack {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  border: 0;
  display: flex;
  /* or display: inline-flex; */
  justify-content: center;
  align-items: center;
}

#info-table {
  font-family: myFirstFont;
}

#offcanvas-body {
  font-family: myFirstFont;
}

#notShown {
  margin-bottom: 0;
  z-index: 120;
  position: relative;
  margin-top: 25%;
  float: left;
  width: auto;
  height: auto;
  color: #4A8376;
  background-color: #013522;
  opacity: 0.7;
  font-family: myFirstFont;
}

#liveData-table {
  display: block;
  color: #fff;
  font-family: myFirstFont;
  min-height: 18vh;
  max-height: 51vh;
}

#liveData-table.table-transparent {
  display: block;
  margin-top: 10px;
  color: #fff;
  font-family: myFirstFont;
  overflow: auto;
}

.overflow-y-scroll {
  background-color: transparent;
  color: transparent;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

#notShownVetes {
  position: relative;
  float: right;
  z-index: 120;
  right: 0;
  opacity: 0.8;
  font-family: myFirstFont;
  margin-right: 0.5%;
}

#liveVetes-table {
  background-color: #013522;
  width: auto;
  height: auto;
  display: block;
  color: #fff;
  font-family: myFirstFont;
}

#closeX {
  position: absolute;
  top: -50px;
  right: -10px;
  width: auto;
}

#closeX2 {
  position: absolute;
  top: -50px;
  right: -10px;
  width: auto;
  height: auto;
  border-color: grey;
  border-radius: 15%;
}

.btn.btn-secondary.dropdown-toggle {
  margin-top: 1vh;
  display: block;
}

#btnX {
  display: flex;
  text-align: center;
  font-family: myFirstFont;
  width: auto;
  color: white;
  background-color: #006346;
  border-color: grey;
  border-radius: 25%;
}

#btnX:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

#treeBtnStart {
  position: relative;
  display: flex;
  z-index: 110;
  margin-top: 5%;
  left: 0;
  right: 10%;
  width: auto;
  background-color: #006346;
  justify-content: center;
}

#treeBtnStart:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

#treeBtn {
  position: relative;
  display: flex;
  z-index: 110;
  margin-top: 5%;
  left: 0;
  right: 10%;
  width: auto;
  background-color: #006346;
  justify-content: center;
}

#treeBtn2 {
  position: relative;
  display: flex;
  z-index: 120;
  margin-top: 15vh;
  left: 0;
  right: 10vh;
  width: 200px;
  background-color: transparent;
  font-family: myFirstFont;
}

.dropdown-toggle {
  position: left;
  display: relative;
  z-index: 110;
  margin-top: 5vh;
  width: auto;
  justify-content: center;
  float: left;
}

.dropdown-menu {
  background-color: transparent;
  border: none;
}

.picture1 {
  display: flex;
  margin: 0 auto;
  height: 90px;
  max-width: 400px;
  background-image: url(../../public/images/logo.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  border-radius: 25px;
  top: 0;
  position: relative;
}

.offcanvas-start {
  background-color: #013522;
  font-family: myFirstFont;
}

#offcanvasExample {
  background-color: #013522;
}

#offcanvasExampleLabel {
  color: #ffffff;
}

.offcanvas-title {
  color: white;
  font-family: myFirstFont;
  font-size: large;
  display: flex;
  text-align: center;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #013522;
}

::-webkit-scrollbar-thumb {
  background-color: #3AB394;
}

.btnInfo {
  width: fit-content;
  background-color: #006346;
  color: #fff;
  justify-content: center;
  border-radius: 8px;
  font-family: myFirstFont;
  border: grey;
  border-style: solid;
  border-width: thin;
}

.btnInfo:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

#treeBtn:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

.video-container {
  position: relative;
  display: block;
  justify-content: center;
  margin-top: 20px;
}

.videoLinks {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#videoLink {
  display: inline;
  position: relative;
  display: flex;
  width: auto;
  text-decoration: none;
  justify-content: center;
  color: #fff;
}

#player {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 1200px) {
#offcanvasScrolling {
    width: 41%;
}

#offcanvasExample1 {
    width: 35%;
}

#offcanvasExample2 {
  width: 35%;
}

#offcanvasExample3 {
  width: 35%;
}

#offcanvasExample4 {
  width: 35%;
}

#offcanvasExample5 {
  width: 35%;
}

#offcanvasExample6 {
  width: 35%;
}

#offcanvasExample7 {
  width: 35%;
}

#offcanvasExample8 {
  width: 35%;
}

#offcanvasExample9 {
  width: 35%;
}

#offcanvasExample10 {
  width: 35%;
}

#offcanvasExample11 {
  width: 35%;
}

#offcanvasExample12 {
  width: 35%;
}

#offcanvasExample13 {
  width: 35%;
}

#offcanvasExample14 {
  width: 35%;
}

#offcanvasExample15 {
  width: 35%;
}

#offcanvasExample16 {
  width: 35%;
}

#offcanvasExample17 {
  width: 35%;
}

#offcanvasExample18 {
  width: 35%;
}

#offcanvasExample19 {
  width: 35%;
}

#offcanvasExample20 {
  width: 35%;
}

#offcanvasExample21 {
  width: 35%;
}

#offcanvasExample22 {
  width: 35%;
}

#offcanvasExample23 {
  width: 35%;
}

#offcanvasExample24 {
  width: 35%;
}
}

@media (max-width: 835px) {
#offcanvasScrolling {
  width: 50%;
}
#offcanvasExample1 {
  width: 50%;
}

#offcanvasExample2 {
  width: 50%;
}

#offcanvasExample3 {
  width: 50%;
}

#offcanvasExample4 {
  width: 50%;
}

#offcanvasExample5 {
  width: 50%;
}

#offcanvasExample6 {
  width: 50%;
}

#offcanvasExample7 {
  width: 50%;
}

#offcanvasExample8 {
  width: 50%;
}

#offcanvasExample9 {
  width: 50%;
}

#offcanvasExample10 {
  width: 50%;
}

#offcanvasExample11 {
  width: 50%;
}

#offcanvasExample12 {
  width: 50%;
}

#offcanvasExample13 {
  width: 50%;
}

#offcanvasExample14 {
  width: 50%;
}

#offcanvasExample15 {
  width: 50%;
}

#offcanvasExample16 {
  width: 50%;
}

#offcanvasExample17 {
  width: 50%;
}

#offcanvasExample18 {
  width: 50%;
}

#offcanvasExample19 {
  width: 50%;
}

#offcanvasExample20 {
  width: 50%;
}

#offcanvasExample21 {
  width: 50%;
}

#offcanvasExample22 {
  width: 50%;
}

#offcanvasExample23 {
  width: 50%;
}

#offcanvasExample24 {
  width: 50%;
}
}

@media (max-width: 600px) {
#offcanvasScrolling {
    width: 100%;
}
#offcanvasExample1 {
  width: 100%;
}

#offcanvasExample2 {
  width: 100%;
}

#offcanvasExample3 {
  width: 100%;
}

#offcanvasExample4 {
  width: 100%;
}

#offcanvasExample5 {
  width: 100%;
}

#offcanvasExample6 {
  width: 100%;
}

#offcanvasExample7 {
  width: 100%;
}

#offcanvasExample8 {
  width: 100%;
}

#offcanvasExample9 {
  width: 100%;
}

#offcanvasExample10 {
  width: 100%;
}

#offcanvasExample11 {
  width: 100%;
}

#offcanvasExample12 {
  width: 100%;
}

#offcanvasExample13 {
  width: 100%;
}

#offcanvasExample14 {
  width: 100%;
}

#offcanvasExample15 {
  width: 100%;
}

#offcanvasExample16 {
  width: 100%;
}

#offcanvasExample17 {
  width: 100%;
}

#offcanvasExample18 {
  width: 100%;
}

#offcanvasExample19 {
  width: 100%;
}

#offcanvasExample20 {
  width: 100%;
}

#offcanvasExample21 {
  width: 100%;
}

#offcanvasExample22 {
  width: 100%;
}

#offcanvasExample23 {
  width: 100%;
}

#offcanvasExample24 {
  width: 100%;
}
}

</style>

<style>
.mapboxgl-popup {
  max-width: 200px;
  color: #fff;
  background-color: transparent;
}

.mapboxgl-popup-content {
  font-family: myFirstFont;
  color: #fff;
  background-color: #013522;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #013522;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #013522;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #013522;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #013522;
}

.marker {
  background-size: cover;
  width: 30px;
  height: 45px;
  cursor: pointer;
}

.marker2 {
  background-size: cover;
  width: 30px;
  height: 45px;
  cursor: pointer;
}

.marker3 {
  background-size: cover;
  width: 30px;
  height: 45px;
  cursor: pointer;
}

.mapboxgl-popup-close-button {
  background-color: #006346;
  color: #fff;
  border-radius: 15%;
  margin-top: 2%;
  margin-right: 2%;
  border-color: grey;
  border-style: solid;
  border-width: thin;
}

.mapboxgl-popup-close-button:hover {
  background-color: #4A8376;
}

.xbutt {
  display: block;
  float: right;
  width: auto;
  height: auto;
  margin-right: 0.5%;
}

#closeX3 {
  position: absolute;
  width: auto;
  height: auto;
  border-color: grey;
  margin: 1.5% -1.5%;
  border-radius: 15%;
  background-color: #006346;
}

#closeX3:hover {
  background-color: #4A8376;
}

#chartH5 {
  position: relative;
  float: left;
  justify-content: center;
  margin-left: 30.5%;
  padding-top: 2.2%;
  display: flex;
}

#makeItBig {
  position: relative;
  justify-self: center;
  float: right;
  margin: 1.5% 0%;
}

#makeItBig2 {
  visibility: hidden;
}

#liveStreamBtn {
  background-color: #4A8376;
  color: #fff;
}

#liveStreamBtn:hover {
  background-color: #006346;
}

#chart {
  position: relative;
  display: block;
  float: right;
  justify-content: center;
  z-index: 130;
  width: auto;
  background: #013522;
  border-color: grey;
  border: solid;
  border-width: thin;
  color: grey;
  font-family: myFirstFont;
  padding-top: 1%;
  cursor: pointer;
  padding: 0% 1.5% 0.5% 1.5%;
}

#chart:hover {
  background-color: #006346;
  opacity: 0.95;
}

#remotevideoundefined {
  visibility: hidden !important;
}

@media (max-width: 1250px) {
  #sensor {
    width: 350px;
    height: 475px;
  }
  #closeX3 {
    margin: 12.5% 2%;
  }
  #chartH5 {
    margin-left: 4%;
  }
}


</style>